import Vue from "vue";
import excel from "vue-excel-export";
import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import draggable from "vuedraggable";
import PartService from "./parts-service";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import PrinterDetails from "../../common/printer-details";
import Utility from "../../../shared/utility";
import BackToTop from "../../common/back-to-top.vue";
import commonAPIService from "../../common/js/common-api-service";
import partsEditService from "./parts-edit-service";
Vue.use(excel);
export default {
  data: () => ({
    userId: EncryptUtility.localStorageDecrypt("userID"),
    actionList: [],
    mainPage: "",
    favouriteList: [],
    pageUrl: "",
    subPage: "",
    showAdd: false,
    subPageUrl: "",
    backSlash: true,
    backSlashParent: true,
    showRefreshDialog: true,
    isEditing: true,
    isFormValid: false,
    projectList: [],
    partTypeList: [],
    partsID: "",
    deletePart: false,
    search: "",
    searchDisabled: true,
    partTypeKey: "",
    substitutionPartListDialog: false,
    isPartEditUpdateFormValid: false,
    substitutionSaveDisabled: false,
    listSubstitutionDetails: [],
    partNumberSearch: "",
    valPartNumFill: false,
    showPartDetails: false,
    partNumSearched: false,
    substitutionList: [],
    numberPart: "",
    descriptionPart: "",
    partNumWarehouse: "",
    editSubstitutionDet: false,
    warehousePartNumList: [],
    warehouseList: [],
    wareSelected: false,
    sequence: 0,
    part_key: "",
    warehouseDialog: false,
    selectedWarehouse: "",
    primaryName: "",
    listWarehouse: [],
    partClassList: [],
    oemList: [],
    odmList: [],
    modelList: [],
    oemModelList: [],
    saveWarehouseData: false,
    warehouseDataAvlbl: false,
    editWarehouseData: false,
    enableSalesOrderItems: false,
    enablePurchaseOrderItems: false,
    saveSubstitutionDet: false,
    enableSalesOrderValue: false,
    enableSalesOrderShowInUI: true,
    enableSalesOrderEnabled: true,
    soStraightSaleValue: false,
    soStraightSaleShowInUI: true,
    soStraightSaleEnabled: true,
    soAdvancedExchangeValue: false,
    soAdvancedExchangeShowInUI: true,
    soAdvancedExchangeEnabled: true,
    soExchangeValue: false,
    soExchangeShowInUI: true,
    soExchangeEnabled: true,
    soCustRepairValue: false,
    soCustRepairShowInUI: true,
    soCustRepairEnabled: true,
    soStraightReturnValue: false,
    soStraightReturnShowInUI: true,
    soStraightReturnEnabled: true,
    enablePurchaseOrdersValue: false,
    enablePurchaseOrdersShowInUI: true,
    enablePurchaseOrdersEnabled: true,
    poStraightPurchaseValue: false,
    poStraightPurchaseShowInUI: true,
    poStraightPurchaseEnabled: true,
    poAdvanceExchangeValue: false,
    poAdvanceExchangeShowInUI: true,
    poAdvanceExchangeEnabled: true,
    poExchangeValue: false,
    poExchangeShowInUI: true,
    poExchangeEnabled: true,
    poStockRepairValue: false,
    poStockRepairShowInUI: true,
    poStockRepairEnabled: true,
    poPOReturnValue: false,
    poPOReturnShowInUI: true,
    poPOReturnEnabled: true,
    bcnValue: false,
    bcnShowInUI: true,
    bcnEnabled: true,
    autoGenerateBCNValue: false,
    autoGenerateBCNShowInUI: true,
    autoGenerateBCNEnabled: true,
    autoGeneratedPrefixValue: "",
    autoGeneratedPrefixShowInUI: true,
    autoGeneratedPrefixEnabled: true,
    serialNoCapturedValue: false,
    serialNoCapturedShowInUI: true,
    serialNoCapturedEnabled: true,
    gobiValue: false,
    gobiShowInUI: true,
    gobiEnabled: true,
    esnIMEIValue: false,
    esnIMEIShowInUI: true,
    esnIMEIEnabled: true,
    ICCIDCapturedValue: false,
    ICCIDCapturedShowInUI: true,
    ICCIDCapturedEnabled: true,
    boxLabelValue: false,
    boxLabelShowInUI: true,
    boxLabelEnabled: true,
    printCommentSheetValue: false,
    printCommentSheetShowInUI: true,
    printCommentSheetEnabled: true,
    promptForNewProductValue: false,
    promptForNewProductShowInUI: true,
    promptForNewProductEnabled: true,
    activateStrikeRuleValue: false,
    activateStrikeRuleShowInUI: true,
    activateStrikeRuleEnabled: true,
    returnWaybillLabelRequiredValue: false,
    returnWaybillLabelRequiredShowInUI: true,
    returnWaybillLabelRequiredEnabled: true,
    promptRevLevelValue: false,
    promptRevLevelShowInUI: true,
    promptRevLevelEnabled: true,
    promptFirmLevelValue: false,
    promptFirmLevelShowInUI: true,
    promptFirmLevelEnabled: true,
    thirdStrikeValue: false,
    thirdStrikeShowInUI: true,
    thirdStrikeEnabled: true,
    minRevLevelValue: "",
    minRevLevelShowInUI: true,
    minRevLevelEnabled: true,
    CRAValue: false,
    CRAShowInUI: true,
    CRAEnabled: true,
    DNCRCVValue: false,
    DNCRCVShowInUI: true,
    DNCRCVEnabled: true,
    IFIRValue: false,
    IFIRShowInUI: true,
    IFIREnabled: true,
    IFIRDaysValue: 0,
    IFIRDaysShowInUI: true,
    IFIRDaysEnabled: false,
    returnsToScrapValue: false,
    returnsToScrapShowInUI: true,
    returnsToScrapEnabled: true,
    DNCBypassScrapValue: false,
    DNCBypassScrapShowInUI: true,
    DNCBypassScrapEnabled: true,
    initialTestTriageValue: false,
    initialTestTriageShowInUI: true,
    initialTestTriageEnabled: true,
    iwTestPathValue: false,
    iwTestPathShowInUI: true,
    iwTestPathEnabled: false,
    owTestPathValue: false,
    owTestPathShowInUI: true,
    owTestPathEnabled: false,
    finalTestPreValue: false,
    finalTestPreShowInUI: true,
    finalTestPreEnabled: true,
    owRepairValue: false,
    owRepairShowInUI: true,
    owRepairEnabled: true,
    iwRepairValue: false,
    iwRepairShowInUI: false,
    iwRepairEnabled: true,
    waitingForPartsValue: false,
    waitingForPartsShowInUI: true,
    waitingForPartsEnabled: true,
    firmwareValue: false,
    firmwareShowInUI: true,
    firmwareEnabled: true,
    finalTestPostValue: false,
    finalTestPostShowInUI: true,
    finalTestPostEnabled: true,
    engineeringEvalValue: false,
    engineeringEvalShowInUI: true,
    engineeringEvalEnabled: true,
    wipeSanitizeValue: false,
    wipeSanitizeShowInUI: true,
    wipeSanitizeEnabled: true,
    cosmeticInspectionValue: false,
    cosmeticInspectionShowInUI: true,
    cosmeticInspectionEnabled: true,
    auditValue: false,
    auditShowInUI: true,
    auditEnabled: true,
    auditSamplingRateValue: "",
    auditSamplingRateShowInUI: true,
    auditSamplingRateEnabled: true,
    harvestValue: false,
    harvestShowInUI: true,
    harvestEnabled: true,
    bucketDialog: false,
    anchorTagElements: false,
    showSelectedWare: true,
    deleteWarehouse: false,
    subPartDelete: false,
    subPartDelDialog: false,
    warehouseDelDialog: false,
    wareDuplicate: 0,
    subDuplicate: 0,
    kitDuplicate: 0,
    bucketDetails: "",
    searchBucketDetails: "",
    kitBOMDialog: false,
    kitBundleDelete: false,
    kitBundleDelDialog: false,
    kitSearch: "",
    kitPartSearch: false,
    kitBundleDetails: false,
    kitBundleList: [],
    showKitBundleDetails: false,
    bucketItems: [],
    buildOptions: [],
    listMemberPartDetails: [],
    kitBundleQty: 1,
    kitBundleActive: false,
    kitBundlePrimaryPart: false,
    kitBundleAddOn: false,
    saveKitBundle: false,
    editKitBundle: false,
    memberPartKey: 0,
    kitSearched: true,
    kitBundlePartDis: true,
    projectBrinks: false,
    panel: [0, 1, 2, 3],
    oemPartNum: "",
    selectedCountry: "",
    countryList: [],
    wareHeaders: [
      {
        text: "Warehouse",
        align: "start",
        value: "ware",
        class: "primary customwhite--text",
      },
      {
        text: "Business",
        value: "business",
        class: "primary customwhite--text",
      },
      {
        text: "Warehouse Type",
        value: "whse_type",
        class: "primary customwhite--text",
      },
      {
        text: "Warehouse Location",
        value: "location",
        class: "primary customwhite--text",
      },
      {
        text: "Actions",
        value: "Actions",
        class: "primary customwhite--text",
      },
    ],
    headersSubstitution: [
      {
        text: "Part Number",
        align: "start",
        value: "part_num",
        class: "primary customwhite--text",
      },
      {
        text: "Description",
        value: "part_desc",
        class: "primary customwhite--text",
      },
      {
        text: "Warehouse",
        value: "ware",
        class: "primary customwhite--text",
      },
      {
        text: "Actions",
        value: "Actions",
        class: "primary customwhite--text",
      },
    ],
    bucketHeaders: [
      {
        text: "Bin",
        align: "start",
        value: "bin",
        class: "primary customwhite--text",
      },
      {
        text: "Count",
        align: "start",
        value: "cnt",
        class: "primary customwhite--text",
      },
    ],
    dataWarehouse: [
      {
        sequence: 0,
        pware_id: 0,
        part_key: 0,
        bu_key: 0,
        ware_key: 0,
        ware: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        business: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        whse_type: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        can_delete: false,
        tab1: [
          {
            enabled: false,
            whse_info: [
              {
                whse_owner: [
                  {
                    company: "",
                    address1: "",
                    city: "",
                    state: "",
                    country: "",
                  },
                ],
                whse_location: [
                  {
                    company: "",
                    address1: "",
                    city: "",
                    state: "",
                    country: "",
                  },
                ],
              },
            ],
            fgi: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            tbe: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            mrb: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            scrap: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            wip: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            onorder_qty: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            allocated: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            backordered_qty: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            onhand_qty: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            available: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            unit_avg_cost: [
              {
                show_in_ui: true,
                enabled: true,
                value: 0,
              },
            ],
            unit_cost_on_latest_po: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            lead_time: [
              {
                show_in_ui: true,
                enabled: true,
                value: 0,
              },
            ],
            avg_lead_time: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            dsi: [
              {
                show_in_ui: true,
                enabled: true,
                value: 0,
              },
            ],
            daily_usage: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            reorder_point: [
              {
                show_in_ui: true,
                enabled: true,
                value: 0,
              },
            ],
            order_point_calc: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
          },
        ],
        tab2: [
          {
            enabled: false,
            prompts: [
              {
                cra: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                dnc_rcv: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                ifir: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                ifir_days: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: 0,
                  },
                ],
                returns_to_scrap: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                dnc_bypass_scrap: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                initial_test: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                iw_test_path: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: 0,
                  },
                ],
                ow_test_path: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: 0,
                  },
                ],
                final_test_pre: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                ow_repair: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                iw_repair: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                waiting_for_parts: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                firmware: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                final_test_post: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                engineering_eval: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                wipe_sanitize: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                cosmetic_insp: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                audit: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                audit_sampling_rate: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: 0,
                  },
                ],
                harvest: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    editData: [
      {
        part_id: 0,
        proj_code: "",
        project: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        part_type: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        part_num: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        part_desc: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        isActive: [
          {
            show_in_ui: true,
            enabled: true,
            value: true,
          },
        ],
        part_class: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        oem: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        odm: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        oem_part: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        model: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        oem_model: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        unit_of_measure: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        bin_type: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        bin_limit: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        packout_ref: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        box_label_size: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        height: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        width: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        length: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        weight: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0.0,
          },
        ],
        daily_usage: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        demand: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        unit_cost: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0.0,
          },
        ],
        sale_price: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0.0,
          },
        ],
        exchange_price: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0.0,
          },
        ],
        repair_price: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0.0,
          },
        ],
        so_warranty_days: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        fgi_dsi_cutoff: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        oem_dsi_cutoff: [
          {
            enabled: false,
            value: 0,
          },
        ],
        oem_parts_per_skid: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        cf_buffer_qty: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        sales_orders: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        so_straight_sale: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        so_advanced_exchange: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        so_exchange: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        so_customer_repair: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        so_straight_return: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        purchase_orders: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        po_straight_purchase: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        po_advanced_exchange: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        po_exchange: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        po_stock_repair: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        po_return: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        bcn: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        auto_generate_bcn: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        auto_generated_prefix: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        serial_number: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        gobi: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        esn_imei: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        iccid: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        box_label: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        print_comment_sheet: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        prompt_for_new: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        third_strike: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        third_strike_visit: [
          {
            show_in_ui: false,
            enabled: false,
            value: 0,
          },
        ],
        return_waybill_required: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        revision_level: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        minimum_revision: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        firmware_revision: [
          {
            show_in_ui: false,
            enabled: false,
            value: false,
          },
        ],
        kitting: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        eco: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        build_options: [
          {
            show_in_ui: false,
            enabled: false,
            build_selections: [
              {
                displaystring: "",
                list_id: 0,
                sequence: 0,
              },
            ],
          },
        ],
        warehouses: [
          {
            show_in_ui: false,
            enabled: false,
            warehouse_list: [
              {
                pware_id: 0,
                part_key: 0,
                bu_key: 0,
                ware_key: 0,
                ware: "",
                business: "",
                location: "",
                whse_type: "",
                fgi: 0,
                tbe: 0,
                mrb: 0,
                scp: 0,
                wip: 0,
                on_po: 0,
                allocated: 0,
                backlog: 0,
                available: 0,
              },
            ],
          },
        ],
        sub_parts: [
          {
            show_in_ui: false,
            enabled: false,
            sub_part_list: [
              {
                sub_part_key: 0,
                part_num: "",
                ware_key: 0,
                ware: "",
                sequence: 1,
              },
            ],
          },
        ],
        bom: [
          {
            show_in_ui: false,
            enabled: false,
            bom_part_list: [],
          },
        ],
        cname: "",
        cdatetime: "",
        ename: "",
        edatetime: "",
        any_activity: false,
        any_bcn_activity: false,
        can_delete: "",
        country_id: 0,
        size: 0,
      },
    ],
    partJSON: [
      {
        part_id: 0,
        proj_key: 0,
        part_number: "",
        part_description: "",
        active: true,
        ptype_key: 0,
        class_key: 0,
        oem_key: 0,
        odm_key: 0,
        oem_part: "",
        model: "",
        oem_model: "",
        uom_key: 0,
        bin_type_key: 0,
        bin_limit: 0,
        packout_ref: "",
        box_label_size_key: 0,
        height: 0,
        width: 0,
        length: 0,
        weight: 0,
        unit_cost: 0,
        sale_price: 0,
        exchange_price: 0,
        repair_price: 0,
        so_warranty_days: 0,
        fgi_dsi_cutoff: 0,
        oem_dsi_cutoff: 0,
        oem_parts_per_skid: 0,
        cf_buffer_qty: 0,
        sales_orders: true,
        so_straight_sale: true,
        so_advanced_exchange: true,
        so_exchange: true,
        so_customer_repair: true,
        so_straight_return: true,
        purchase_orders: true,
        po_straight_purchase: true,
        po_advanced_exchange: true,
        po_exchange: true,
        po_stock_repair: true,
        po_return: true,
        bcn: true,
        auto_generate_bcn: false,
        auto_generated_prefix: "",
        serial_number: true,
        gobi: true,
        esn_imei: true,
        iccid: true,
        box_label: true,
        print_comment_sheet: true,
        prompt_for_new: true,
        third_strike: true,
        third_strike_visit: 0,
        return_waybill_required: true,
        revision_level: true,
        minimum_revision: "",
        firmware_revision: true,
        build: [],
        warehouse: [],
        kit: "",
        eco: "",
        subs: [],
        user_key: EncryptUtility.localStorageDecrypt("userID"),
      },
    ],
    headersMemberPart: [
      {
        text: "Member Part#",
        align: "start",
        value: "part_num",
        class: "primary customwhite--text",
      },
      {
        text: "Description",
        value: "part_desc",
        class: "primary customwhite--text",
      },
      {
        text: "Quantity",
        value: "qty",
        class: "primary customwhite--text",
      },
      {
        text: "Is Primary Part",
        value: "primary_pn",
        class: "primary customwhite--text",
      },
      {
        text: "Status",
        value: "active",
        class: "primary customwhite--text",
      },
      {
        text: "Actions",
        value: "Actions",
        class: "primary customwhite--text",
      },
    ],
    numberRule: [(v) => /^\d*\.?\d*$/.test(v) || "Only numbers are allowed"],
    pageChanged: false,
    createdDate: "",
    editedDate: "",
    updatePart: true,
    fab: false,
    maxInputValue: [(v) => parseInt(v) < 2147483647 || "Field should be less than 2,147,483,647"],
    keywords: ["PLASMA TV", "TV", "DISPLAY", "MONITOR", "PROJECTOR", "LCD", "LCD TV"],
    matchingClassIds: [],
    displaySizeVal: 0,
    displaySizeShow: false,
    showPartDialog: false,
    changedPartNum: "",
  }),
  async created() {
    this.primaryName = this.$cookies.get("primaryName");
    this.route = this.$route.path.replace("/", "").split("/")[0];
    let data = await AddFavourite.getParentPageAction(this.userId, this.route);
    this.mainPage = data?.mainPage;
    this.favouriteList = data?.favouriteList;
    this.pageUrl = data?.pageUrl;
    this.subPage = data?.subPage;
    this.showAdd = data?.showAdd;
    this.subPageUrl = data?.subPageUrl;
    this.appPageName = data?.appPageName;

    let actionData = await AddFavourite.getParentPageAction(this.userId, this.subPageUrl);
    this.actionList = actionData?.actionList;
    let pagePermissions = data?.actionList;
    pagePermissions?.forEach((element) => {
      this.actionList.push(element);
    });
    if (this.actionSetter("PartDelete")) {
      this.deletePart = true;
    } else {
      this.deletePart = false;
    }
    this.partsID = atob(this.$route.params.id);
    let countryData = await commonAPIService.countryListNew(this.userId, "", 1, "get");
    this.countryList = countryData.CountryInfo;
    this.getPartHeaderByID(this.partsID);
  },
  //Refresh  Warning
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  //Refresh to check if Router is changes
  beforeRouteLeave(to, from, next) {
    this.isEditing = this.pageChanged;
    let pageJson = JSON.parse(JSON.stringify(this.editData[0]));
    let warehouseChanged = this.warehouseList.forEach((v) => {
      v.isEdited = false;
    });
    if (JSON.stringify(pageJson) != JSON.stringify(this.editData[0]) || this.pageChanged) {
      this.isEditing = true;
    } else if (warehouseChanged == undefined) {
      this.isEditing = false;
    } else {
      this.isEditing = false;
    }
    if (this.isEditing) {
      if (window.confirm("Leave without saving?")) {
        next();
      } else {
        return false;
      }
    }
    next();
  },
  mounted() { },
  methods: {
    //to check if its a number of not
    isNumber(event, loc) {
      if (loc == "number") {
        let value = Utility.isNumber(event)
        return value
      }
      else {
        let value = Utility.isNumberWithPeriod(event)
        return value
      }
    },
    // Scroll Check method
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    // To go to the top
    toTop() {
      this.$vuetify.goTo(0);
    },

    //Action Setter
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    openDialogPart() {
      this.showPartDialog = true
    },
    //Parent navgation
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = "";
    },
    //Clear Data Warehouse
    clearDataWarehouse() {
      this.CRAValue = false;
      this.CRAShowInUI = true;
      this.CRAEnabled = true;
      this.DNCRCVValue = false;
      this.DNCRCVShowInUI = true;
      this.DNCRCVEnabled = true;
      this.IFIRValue = false;
      this.IFIRShowInUI = true;
      this.IFIREnabled = true;
      this.IFIRDaysValue = 0;
      this.IFIRDaysShowInUI = true;
      this.IFIRDaysEnabled = false;
      this.returnsToScrapValue = false;
      this.returnsToScrapShowInUI = true;
      this.returnsToScrapEnabled = true;
      this.DNCBypassScrapValue = false;
      this.DNCBypassScrapShowInUI = true;
      this.DNCBypassScrapEnabled = true;
      this.initialTestTriageValue = false;
      this.initialTestTriageShowInUI = true;
      this.initialTestTriageEnabled = true;
      this.iwTestPathValue = false;
      this.iwTestPathShowInUI = true;
      this.iwTestPathEnabled = false;
      this.owTestPathValue = false;
      this.owTestPathShowInUI = true;
      this.owTestPathEnabled = false;
      this.finalTestPreValue = false;
      this.finalTestPreShowInUI = true;
      this.finalTestPreEnabled = true;
      this.owRepairValue = false;
      this.owRepairShowInUI = true;
      this.owRepairEnabled = true;
      this.iwRepairValue = false;
      this.iwRepairShowInUI = true;
      this.iwRepairEnabled = true;
      this.waitingForPartsValue = false;
      this.waitingForPartsShowInUI = true;
      this.waitingForPartsEnabled = true;
      this.firmwareValue = false;
      this.firmwareShowInUI = true;
      this.firmwareEnabled = true;
      this.finalTestPostValue = false;
      this.finalTestPostShowInUI = true;
      this.finalTestPostEnabled = true;
      this.engineeringEvalValue = false;
      this.engineeringEvalShowInUI = true;
      this.engineeringEvalEnabled = true;
      this.wipeSanitizeValue = false;
      this.wipeSanitizeShowInUI = true;
      this.wipeSanitizeEnabled = true;
      this.cosmeticInspectionValue = false;
      this.cosmeticInspectionShowInUI = true;
      this.cosmeticInspectionEnabled = true;
      this.auditValue = false;
      this.auditShowInUI = true;
      this.auditEnabled = true;
      this.auditSamplingRateValue = "";
      this.auditSamplingRateShowInUI = true;
      this.auditSamplingRateEnabled = true;
      this.harvestValue = false;
      this.harvestShowInUI = true;
      this.harvestEnabled = true;
      this.dataWarehouse[0] = {
        sequence: 0,
        pware_id: 0,
        part_key: 0,
        bu_key: 0,
        ware_key: 0,
        ware: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        business: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        whse_type: [
          {
            show_in_ui: false,
            enabled: false,
            value: "",
          },
        ],
        can_delete: false,
        tab1: [
          {
            enabled: false,
            whse_info: [
              {
                whse_owner: [
                  {
                    company: "",
                    address1: "",
                    city: "",
                    state: "",
                    country: "",
                  },
                ],
                whse_location: [
                  {
                    company: "",
                    address1: "",
                    city: "",
                    state: "",
                    country: "",
                  },
                ],
              },
            ],
            fgi: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            tbe: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            mrb: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            scrap: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            wip: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            onorder_qty: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            allocated: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            backordered_qty: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            onhand_qty: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            available: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            unit_avg_cost: [
              {
                show_in_ui: true,
                enabled: true,
                value: 0,
              },
            ],
            unit_cost_on_latest_po: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            lead_time: [
              {
                show_in_ui: true,
                enabled: true,
                value: 0,
              },
            ],
            avg_lead_time: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            dsi: [
              {
                show_in_ui: true,
                enabled: true,
                value: 0,
              },
            ],
            daily_usage: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
            reorder_point: [
              {
                show_in_ui: true,
                enabled: true,
                value: 0,
              },
            ],
            order_point_calc: [
              {
                show_in_ui: false,
                enabled: false,
                value: 0,
              },
            ],
          },
        ],
        tab2: [
          {
            enabled: false,
            prompts: [
              {
                cra: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                dnc_rcv: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                ifir: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                ifir_days: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: 0,
                  },
                ],
                returns_to_scrap: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                dnc_bypass_scrap: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                initial_test: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                iw_test_path: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: 0,
                  },
                ],
                ow_test_path: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: 0,
                  },
                ],
                final_test_pre: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                ow_repair: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                iw_repair: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                waiting_for_parts: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                firmware: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                final_test_post: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                engineering_eval: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                wipe_sanitize: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                cosmetic_insp: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                audit: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
                audit_sampling_rate: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: 0,
                  },
                ],
                harvest: [
                  {
                    show_in_ui: false,
                    enabled: false,
                    value: false,
                  },
                ],
              },
            ],
          },
        ],
      };
    },
    //Reset Function
    resetFunction() {
      this.projectBrinks = false;
      this.kitSearched = true;
      this.kitBundlePartDis = true;
      this.memberPartKey = 0;
      this.saveKitBundle = false;
      this.editKitBundle = false;
      this.kitBundlePrimaryPart = false;
      this.kitBundleAddOn = false;
      this.kitBundleQty = 1;
      this.kitBundleActive = false;
      this.showKitBundleDetails = false;
      this.kitBundleList = [];
      this.kitBundleDetails = false;
      this.kitPartSearch = false;
      this.kitSearch = "";
      this.kitBundleDelete = false;
      this.kitBOMDialog = false;
      this.listMemberPartDetails = [];
      this.search = "";
      this.searchDisabled = true;
      this.subPartDelDialog = false;
      this.warehouseDelDialog = false;
      this.tab = null;
      this.wareDuplicate = 0;
      this.subDuplicate = 0;
      this.kitDuplicate = 0;
      this.subPartDelete = false;
      this.deleteWarehouse = false;
      this.showSelectedWare = true;
      this.anchorTagElements = false;
      this.bucketDialog = false;
      this.bucketDetails = "";
      this.bucketItems = [];
      this.searchBucketDetails = "";
      this.buildOptions = [];
      this.minRevLevelValue = "";
      this.minRevLevelShowInUI = true;
      this.minRevLevelEnabled = true;
      this.enableSalesOrderValue = false;
      this.enableSalesOrderShowInUI = true;
      this.enableSalesOrderEnabled = true;
      this.soStraightSaleValue = false;
      this.soStraightSaleShowInUI = true;
      this.soStraightSaleEnabled = true;
      this.soAdvancedExchangeValue = false;
      this.soAdvancedExchangeShowInUI = true;
      this.soAdvancedExchangeEnabled = true;
      this.soExchangeValue = false;
      this.soExchangeShowInUI = true;
      this.soExchangeEnabled = true;
      this.soCustRepairValue = false;
      this.soCustRepairShowInUI = true;
      this.soCustRepairEnabled = true;
      this.soStraightReturnValue = false;
      this.soStraightReturnShowInUI = true;
      this.soStraightReturnEnabled = true;
      this.enablePurchaseOrdersValue = false;
      this.enablePurchaseOrdersShowInUI = true;
      this.enablePurchaseOrdersEnabled = true;
      this.poStraightPurchaseValue = false;
      this.poStraightPurchaseShowInUI = true;
      this.poStraightPurchaseEnabled = true;
      this.poAdvanceExchangeValue = false;
      this.poAdvanceExchangeShowInUI = true;
      this.poAdvanceExchangeEnabled = true;
      this.poExchangeValue = false;
      this.poExchangeShowInUI = true;
      this.poExchangeEnabled = true;
      this.poStockRepairValue = false;
      this.poStockRepairShowInUI = true;
      this.poStockRepairEnabled = true;
      this.poPOReturnValue = false;
      this.poPOReturnShowInUI = true;
      this.poPOReturnEnabled = true;
      this.bcnValue = false;
      this.bcnShowInUI = true;
      this.bcnEnabled = true;
      this.autoGenerateBCNValue = false;
      this.autoGenerateBCNShowInUI = true;
      this.autoGenerateBCNEnabled = true;
      this.autoGeneratedPrefixValue = "";
      this.autoGeneratedPrefixShowInUI = true;
      this.autoGeneratedPrefixEnabled = true;
      this.serialNoCapturedValue = false;
      this.serialNoCapturedShowInUI = true;
      this.serialNoCapturedEnabled = true;
      this.gobiValue = false;
      this.gobiShowInUI = true;
      this.gobiEnabled = true;
      this.esnIMEIValue = false;
      this.esnIMEIShowInUI = true;
      this.esnIMEIEnabled = true;
      this.ICCIDCapturedValue = false;
      this.ICCIDCapturedShowInUI = true;
      this.ICCIDCapturedEnabled = true;
      this.boxLabelValue = false;
      this.boxLabelShowInUI = true;
      this.boxLabelEnabled = true;
      this.printCommentSheetValue = false;
      this.printCommentSheetShowInUI = true;
      this.printCommentSheetEnabled = true;
      this.promptForNewProductValue = false;
      this.promptForNewProductShowInUI = true;
      this.promptForNewProductEnabled = true;
      this.activateStrikeRuleValue = false;
      this.activateStrikeRuleShowInUI = true;
      this.activateStrikeRuleEnabled = true;
      this.returnWaybillLabelRequiredValue = false;
      this.returnWaybillLabelRequiredShowInUI = true;
      this.returnWaybillLabelRequiredEnabled = true;
      this.promptRevLevelValue = false;
      this.promptRevLevelShowInUI = true;
      this.promptRevLevelEnabled = true;
      this.promptFirmLevelValue = false;
      this.promptFirmLevelShowInUI = true;
      this.promptFirmLevelEnabled = true;
      this.thirdStrikeValue = false;
      this.thirdStrikeShowInUI = true;
      this.thirdStrikeEnabled = true;
      this.primaryName = "";
      this.oemModelList = [];
      this.enablePurchaseOrderItems = false;
      this.enableSalesOrderItems = false;
      this.modelList = [];
      this.odmList = [];
      this.oemList = [];
      this.partClassList = [];
      this.editWarehouseData = false;
      this.saveWarehouseData = false;
      this.warehouseDataAvlbl = false;
      this.listWarehouse = [];
      this.warehouseDialog = false;
      this.wareSelected = false;
      this.sequence = 0;
      this.editSubstitutionDet = false;
      this.saveSubstitutionDet = false;
      this.partNumSearched = false;
      this.warehousePartNumList = [];
      this.partNumWarehouse = "";
      this.descriptionPart = "";
      this.numberPart = "";
      this.showPartDetails = false;
      this.substitutionList = [];
      this.valPartNumFill = false;
      this.partNumberSearch = "";
      this.substitutionSaveDisabled = false;
      this.substitutionPartListDialog = false;
      this.listSubstitutionDetails = [];
      this.warehouseList = [];
      this.partTypeKey = "";
      this.partTypeList = [];
      this.projectList = [];
      this.isFormValid = false;
      this.oemPartNum = "";
      this.selectedCountry = "";
      this.partsID = atob(this.$route.params.id);
      this.displaySizeShow = false;
      this.displaySizeVal = 0;
      this.$refs.partsEditUpdateForm.resetValidation();
      this.getPartHeaderByID(this.partsID);
    },
    //Reset JSON Arrays
    clearWarehouseFields() {
      //Warehouse Fields
      this.dataWarehouse[0].tab1[0].unit_avg_cost[0].value = 0;
      this.dataWarehouse[0].tab1[0].dsi[0].value = 0;
      this.dataWarehouse[0].tab1[0].lead_time[0].value = 0;
      this.dataWarehouse[0].tab1[0].reorder_point[0].value = 0;
      this.CRAValue = false;
      this.DNCRCVValue = false;
      this.IFIRValue = false;
      this.IFIRDaysValue = 0;
      this.returnsToScrapValue = false;
      this.DNCBypassScrapValue = false;
      this.initialTestTriageValue = false;
      this.iwTestPathValue = false;
      this.owTestPathValue = false;
      this.finalTestPreValue = false;
      this.owRepairValue = false;
      this.iwRepairValue = false;
      this.waitingForPartsValue = false;
      this.firmwareValue = false;
      this.finalTestPostValue = false;
      this.engineeringEvalValue = false;
      this.wipeSanitizeValue = false;
      this.cosmeticInspectionValue = false;
      this.auditValue = false;
      this.auditSamplingRateValue = "";
      this.harvestValue = false;
    },
    //Get Parts Header based on Part ID
    async getPartHeaderByID(part_id) {
      let partHeaderData = await PartService.getPartHeaderByID("get", this.userId, part_id);
      if (partHeaderData) {
        Object.assign(this.editData, partHeaderData);
      }
      this.getPartClass();
      this.getOEMDetails();
      this.getODMDetails();
      this.getModelDetails();
      this.getOEMModelDetails();
      this.getUOMDetails();
      this.getBinTypeDetails();
      this.getLabelSizeDetails();
      this.getBuildActionDetails();
      if (partHeaderData) {
        if (this.editData) {
          let projectData = await commonAPIService.getProject("get", this.userId);
          this.projectList = projectData.ProjectInfo;
          let partTypeData = await PartService.getPartType("get", this.editData[0].project[0].value, false);
          this.partTypeList = partTypeData;
          //Data table data integration
          this.warehouseList = this.editData[0]?.warehouses[0]?.warehouse_list.map((obj, index) => {
            return {
              ...obj,
              sequence: index + 1,
              isedited: false,
            };
          });
          if (this.warehouseList.length > 0) {
            this.searchDisabled = false;
          }
          this.listMemberPartDetails = this.editData[0]?.bom[0]?.bom_part_list;
          this.listSubstitutionDetails = this.editData[0]?.sub_parts[0]?.sub_part_list;
          this.enableSalesOrderValue = this.editData[0]?.sales_orders[0]?.value;
          this.enableSalesOrderShowInUI = this.editData[0]?.sales_orders[0]?.show_in_ui;
          this.enableSalesOrderEnabled = this.editData[0]?.sales_orders[0]?.enabled;
          this.soStraightSaleValue = this.editData[0]?.sales_orders[0]?.value;
          this.soStraightSaleShowInUI = this.editData[0]?.so_straight_sale[0]?.show_in_ui;
          this.soStraightSaleEnabled = this.editData[0]?.so_straight_sale[0]?.enabled;
          this.soAdvancedExchangeValue = this.editData[0]?.so_advanced_exchange[0]?.value;
          this.soAdvancedExchangeShowInUI = this.editData[0]?.so_advanced_exchange[0]?.show_in_ui;
          this.soAdvancedExchangeEnabled = this.editData[0]?.so_advanced_exchange[0]?.enabled;
          this.soExchangeValue = this.editData[0]?.so_exchange[0]?.value;
          this.soExchangeShowInUI = this.editData[0]?.so_exchange[0]?.show_in_ui;
          this.soExchangeEnabled = this.editData[0]?.so_exchange[0]?.enabled;
          this.soCustRepairValue = this.editData[0]?.so_customer_repair[0]?.value;
          this.soCustRepairShowInUI = this.editData[0]?.so_customer_repair[0]?.show_in_ui;
          this.soCustRepairEnabled = this.editData[0]?.so_customer_repair[0]?.enabled;
          this.soStraightReturnValue = this.editData[0]?.so_straight_return[0]?.value;
          this.soStraightReturnShowInUI = this.editData[0]?.so_straight_return[0]?.show_in_ui;
          this.soStraightReturnEnabled = this.editData[0]?.so_straight_return[0]?.enabled;
          this.enablePurchaseOrdersValue = this.editData[0]?.purchase_orders[0]?.value;
          this.enablePurchaseOrdersShowInUI = this.editData[0]?.purchase_orders[0]?.show_in_ui;
          this.enablePurchaseOrdersEnabled = this.editData[0]?.purchase_orders[0]?.enabled;
          this.poStraightPurchaseValue = this.editData[0]?.po_straight_purchase[0]?.value;
          this.poStraightPurchaseShowInUI = this.editData[0]?.po_straight_purchase[0]?.show_in_ui;
          this.poStraightPurchaseEnabled = this.editData[0]?.po_straight_purchase[0]?.enabled;
          this.poAdvanceExchangeValue = this.editData[0]?.po_advanced_exchange[0]?.value;
          this.poAdvanceExchangeShowInUI = this.editData[0]?.po_advanced_exchange[0]?.show_in_ui;
          this.poAdvanceExchangeEnabled = this.editData[0]?.po_advanced_exchange[0]?.enabled;
          this.poExchangeValue = this.editData[0]?.po_exchange[0]?.value;
          this.poExchangeShowInUI = this.editData[0]?.po_exchange[0]?.show_in_ui;
          this.poExchangeEnabled = this.editData[0]?.po_exchange[0]?.enabled;
          this.poStockRepairValue = this.editData[0]?.po_stock_repair[0]?.value;
          this.poStockRepairShowInUI = this.editData[0]?.po_stock_repair[0]?.show_in_ui;
          this.poStockRepairEnabled = this.editData[0]?.po_stock_repair[0]?.enabled;
          this.poPOReturnValue = this.editData[0]?.po_return[0]?.value;
          this.poPOReturnShowInUI = this.editData[0]?.po_return[0]?.show_in_ui;
          this.poPOReturnEnabled = this.editData[0]?.po_return[0]?.enabled;
          this.bcnValue = this.editData[0]?.bcn[0]?.value;
          this.bcnShowInUI = this.editData[0]?.bcn[0]?.show_in_ui;
          this.bcnEnabled = this.editData[0]?.bcn[0]?.enabled;
          this.autoGenerateBCNValue = this.editData[0]?.auto_generate_bcn[0]?.value;
          this.autoGenerateBCNShowInUI = this.editData[0]?.auto_generate_bcn[0]?.show_in_ui;
          this.autoGenerateBCNEnabled = this.editData[0]?.auto_generate_bcn[0]?.enabled;
          this.autoGeneratedPrefixValue = this.editData[0]?.auto_generated_prefix[0]?.value;
          this.autoGeneratedPrefixShowInUI = this.editData[0]?.auto_generated_prefix[0]?.show_in_ui;
          this.autoGeneratedPrefixEnabled = this.editData[0]?.auto_generated_prefix[0]?.enabled;
          this.serialNoCapturedValue = this.editData[0]?.serial_number[0]?.value;
          this.serialNoCapturedShowInUI = this.editData[0]?.serial_number[0]?.show_in_ui;
          this.serialNoCapturedEnabled = this.editData[0]?.serial_number[0]?.enabled;
          this.gobiValue = this.editData[0]?.gobi[0]?.value;
          this.gobiShowInUI = this.editData[0]?.gobi[0]?.show_in_ui;
          this.gobiEnabled = this.editData[0]?.gobi[0]?.enabled;
          this.esnIMEIValue = this.editData[0]?.esn_imei[0]?.value;
          this.esnIMEIShowInUI = this.editData[0]?.esn_imei[0]?.show_in_ui;
          this.esnIMEIEnabled = this.editData[0]?.esn_imei[0]?.enabled;
          this.ICCIDCapturedValue = this.editData[0]?.iccid[0]?.value;
          this.ICCIDCapturedShowInUI = this.editData[0]?.iccid[0]?.show_in_ui;
          this.ICCIDCapturedEnabled = this.editData[0]?.iccid[0]?.enabled;
          this.boxLabelValue = this.editData[0]?.box_label[0]?.value;
          this.boxLabelShowInUI = this.editData[0]?.box_label[0]?.show_in_ui;
          this.boxLabelEnabled = this.editData[0]?.box_label[0]?.enabled;
          this.printCommentSheetValue = this.editData[0]?.print_comment_sheet[0]?.value;
          this.printCommentSheetShowInUI = this.editData[0]?.print_comment_sheet[0]?.show_in_ui;
          this.printCommentSheetEnabled = this.editData[0]?.print_comment_sheet[0]?.enabled;
          this.promptForNewProductValue = this.editData[0]?.prompt_for_new[0]?.value;
          this.promptForNewProductShowInUI = this.editData[0]?.prompt_for_new[0]?.show_in_ui;
          this.promptForNewProductEnabled = this.editData[0]?.prompt_for_new[0]?.enabled;
          this.activateStrikeRuleValue = this.editData[0]?.third_strike[0]?.value;
          this.activateStrikeRuleShowInUI = this.editData[0]?.third_strike[0]?.show_in_ui;
          this.activateStrikeRuleEnabled = this.editData[0]?.third_strike[0]?.enabled;
          this.returnWaybillLabelRequiredValue = this.editData[0]?.return_waybill_required[0]?.value;
          this.returnWaybillLabelRequiredShowInUI = this.editData[0]?.return_waybill_required[0]?.show_in_ui;
          this.returnWaybillLabelRequiredEnabled = this.editData[0]?.return_waybill_required[0]?.enabled;
          this.promptRevLevelValue = this.editData[0]?.revision_level[0]?.value;
          this.promptRevLevelShowInUI = this.editData[0]?.revision_level[0]?.show_in_ui;
          this.promptRevLevelEnabled = this.editData[0]?.revision_level[0]?.enabled;
          this.promptFirmLevelValue = this.editData[0]?.firmware_revision[0]?.value;
          this.promptFirmLevelShowInUI = this.editData[0]?.firmware_revision[0]?.show_in_ui;
          this.promptFirmLevelEnabled = this.editData[0]?.firmware_revision[0]?.enabled;
          this.thirdStrikeValue = this.editData[0]?.third_strike_visit[0]?.value;
          this.thirdStrikeShowInUI = this.editData[0]?.third_strike_visit[0]?.show_in_ui;
          this.thirdStrikeEnabled = this.editData[0]?.third_strike_visit[0].enabled;
          this.minRevLevelValue = this.editData[0]?.minimum_revision[0]?.value;
          this.minRevLevelShowInUI = this.editData[0].minimum_revision[0]?.show_in_ui;
          this.minRevLevelEnabled = this.editData[0]?.minimum_revision[0]?.enabled;
          this.partJSON[0].oem_model = this.editData[0].oem_model[0].value;
          this.partJSON[0].model = this.editData[0].model[0].value;
          this.oemPartNum = this.editData[0].oem_part[0].value;
          const items = this.editData[0].build_options[0].build_selections.filter((obj) => obj.sequence !== 0);
          const sortedItems = items.sort((a, b) => a.sequence - b.sequence);
          this.editData[0].build_options[0].build_selections = sortedItems;
          this.createdDate = Utility.convertESTToLocal(this.editData[0]?.cdatetime);
          this.editedDate = Utility.convertESTToLocal(this.editData[0]?.edatetime);
          this.selectedCountry = this.editData[0]?.country_id;
          this.displaySizeVal = this.editData[0]?.size;
          if (
            this.editData[0].part_class[0].value !== null &&
            this.editData[0].part_class[0].value !== undefined &&
            this.editData[0].part_class[0].value !== ""
          ) {
            this.showDisplaySize();
          }
        }
      }
    },
    //Dropdown API - Start
    //Get Part Class
    async getPartClass() {
      if (this.editData[0].project[0].value !== null && this.editData[0].project[0].value !== undefined) {
        let partClassData = await PartService.getPartClass("get", this.userId, this.editData[0].project[0].value);
        this.matchingClassIds = this.compareClasses(partClassData, this.keywords);
        this.partClassList = partClassData;
        if (this.partClassList) {
          this.getWarehouseData();
        }
      }
    },
    //Comparing and getting the Part Class keys
    compareClasses(data, keywords) {
      const matchingIds = [];
      for (const item of data) {
        const itemClass = item.class.toUpperCase();
        let isMatch = false;
        for (const keyword of keywords) {
          const normalizedKeyword = keyword.toUpperCase();
          if (!isMatch && (itemClass === normalizedKeyword || itemClass.includes(normalizedKeyword))) {
            isMatch = true;
            break;
          }
        }
        if (isMatch) {
          matchingIds.push(item.class_id);
        }
      }
      return matchingIds;
    },
    //Whether to show display size or not
    showDisplaySize() {
      if (this.editData[0].part_type[0].value == 1 || this.editData[0].part_type[0].value == 2 || this.editData[0].part_type[0].value == 3) {
        let display = this.matchingClassIds.find((element) => element == this.editData[0].part_class[0].value);
        if (display !== undefined && display !== null) {
          this.displaySizeShow = true;
        } else {
          this.displaySizeShow = false;
          this.displaySizeVal = 0;
        }
      }
    },
    //Get OEM Details
    async getOEMDetails() {
      let OEMData = await PartService.getOEMDetails("get", this.userId, this.editData[0].project[0].value);
      this.oemList = OEMData;
    },
    //Get ODM Details
    async getODMDetails() {
      let ODMData = await PartService.getODMDetails("get", this.userId, this.editData[0].project[0].value);
      this.odmList = ODMData;
    },
    //Get Model Details
    async getModelDetails() {
      let modelData = await PartService.getModelDetails("get", this.userId, this.editData[0].project[0].value);
      this.modelList = modelData;
    },
    //Get OEM Model Details
    async getOEMModelDetails() {
      let OEMModelData = await PartService.getOEMModelDetails("get", this.userId, this.editData[0].project[0].value);
      this.oemModelList = OEMModelData;
    },
    //Get UOM Details
    async getUOMDetails() {
      let UOMData = await PartService.getUOMDetails("get");
      this.unitOfMeasureList = UOMData;
    },
    //Get Bin Type Details
    async getBinTypeDetails() {
      let binTypeData = await PartService.getBinTypeDetails("get");
      this.binTypeList = binTypeData;
    },
    //Get Label Size Details
    async getLabelSizeDetails() {
      let labelSizeData = await PartService.getLabelSizeDetails("get");
      this.boxLabelSizeList = labelSizeData;
    },
    //Get Build Action Details
    async getBuildActionDetails() {
      let buildActionData = await PartService.getBuildActionDetails("get");
      this.buildOptionsList = buildActionData;
    },
    //Dropdown - End
    //Sales Order and Purchase Order checkbox - Start
    //Enable Sales Order Selected
    async enableSalesOrderSelected(name, value) {
      this.pageChanged = true;
      let wareKey = 0; // Do not delete - CP
      let controlUIObj = {
        ptype_key: parseInt(this.editData[0].part_type[0].value),
        checkbox_name: name,
        checkbox_value: value,
        bcn: this.bcnValue,
        serial_number: this.serialNoCapturedValue,
        ware_key: parseInt(wareKey),
        iw_repair: this.iwRepairValue,
        ow_repair: this.owRepairValue,
        gobi: this.gobiValue,
        esn_imei: this.esnIMEIValue,
        iccid: this.ICCIDCapturedValue,
      };
      let controlUIData = await PartService.postControlUIDetails("post", controlUIObj);
      if (controlUIData[0] !== undefined) {
        if (controlUIData[0].so_advanced_exchange !== undefined) {
          this.soAdvancedExchangeValue = controlUIData[0].so_advanced_exchange[0].value;
          this.soAdvancedExchangeShowInUI = controlUIData[0].so_advanced_exchange[0].show_in_ui;
          this.soAdvancedExchangeEnabled = controlUIData[0].so_advanced_exchange[0].enabled;
        }
        if (controlUIData[0].so_customer_repair !== undefined) {
          this.soCustRepairValue = controlUIData[0].so_customer_repair[0].value;
          this.soCustRepairShowInUI = controlUIData[0].so_customer_repair[0].show_in_ui;
          this.soCustRepairEnabled = controlUIData[0].so_customer_repair[0].enabled;
        }
        if (controlUIData[0].so_exchange !== undefined) {
          this.soExchangeValue = controlUIData[0].so_exchange[0].value;
          this.soExchangeShowInUI = controlUIData[0].so_exchange[0].show_in_ui;
          this.soExchangeEnabled = controlUIData[0].so_exchange[0].enabled;
        }
        if (controlUIData[0].so_straight_return !== undefined) {
          this.soStraightReturnValue = controlUIData[0].so_straight_return[0].value;
          this.soStraightReturnShowInUI = controlUIData[0].so_straight_return[0].show_in_ui;
          this.soStraightReturnEnabled = controlUIData[0].so_straight_return[0].enabled;
        }
        if (controlUIData[0].so_straight_sale !== undefined) {
          this.soStraightSaleValue = controlUIData[0].so_straight_sale[0].value;
          this.soStraightSaleShowInUI = controlUIData[0].so_straight_sale[0].show_in_ui;
          this.soStraightSaleEnabled = controlUIData[0].so_straight_sale[0].enabled;
        }
      }
    },
    //Enable Purchase Order Selected
    async enablePurchaseOrderSelected(name, value) {
      this.pageChanged = true;
      let wareKey = 0; // Do not delete - CP
      let controlUIObj = {
        ptype_key: parseInt(this.editData[0].part_type[0].value),
        checkbox_name: name,
        checkbox_value: value,
        bcn: this.bcnValue,
        serial_number: this.serialNoCapturedValue,
        ware_key: parseInt(wareKey),
        iw_repair: this.iwRepairValue,
        ow_repair: this.owRepairValue,
        gobi: this.gobiValue,
        esn_imei: this.esnIMEIValue,
        iccid: this.ICCIDCapturedValue,
      };
      let controlUIData = await PartService.postControlUIDetails("post", controlUIObj);
      if (controlUIData[0] !== undefined) {
        if (controlUIData[0].po_advanced_exchange !== undefined) {
          this.poAdvanceExchangeValue = controlUIData[0].po_advanced_exchange[0].value;
          this.poAdvanceExchangeShowInUI = controlUIData[0].po_advanced_exchange[0].show_in_ui;
          this.poAdvanceExchangeEnabled = controlUIData[0].po_advanced_exchange[0].enabled;
        }
        if (controlUIData[0].po_exchange !== undefined) {
          this.poExchangeValue = controlUIData[0].po_exchange[0].value;
          this.poExchangeShowInUI = controlUIData[0].po_exchange[0].show_in_ui;
          this.poExchangeEnabled = controlUIData[0].po_exchange[0].enabled;
        }
        if (controlUIData[0].po_return !== undefined) {
          this.poPOReturnValue = controlUIData[0].po_return[0].value;
          this.poPOReturnShowInUI = controlUIData[0].po_return[0].show_in_ui;
          this.poPOReturnEnabled = controlUIData[0].po_return[0].enabled;
        }
        if (controlUIData[0].po_stock_repair !== undefined) {
          this.poStockRepairValue = controlUIData[0].po_stock_repair[0].value;
          this.poStockRepairShowInUI = controlUIData[0].po_stock_repair[0].show_in_ui;
          this.poStockRepairEnabled = controlUIData[0].po_stock_repair[0].enabled;
        }
        if (controlUIData[0].po_straight_purchase !== undefined) {
          this.poStraightPurchaseValue = controlUIData[0].po_straight_purchase[0].value;
          this.poStraightPurchaseShowInUI = controlUIData[0].po_straight_purchase[0].show_in_ui;
          this.poStraightPurchaseEnabled = controlUIData[0].po_straight_purchase[0].enabled;
        }
      }
    },
    //Sales Order and Purchase Order checkbox - End
    //API for validation of checkboxes
    async postControlUIDetails(name, value) {
      this.pageChanged = true;
      let wareKey = 0; //Do not delete - CP
      let controlUIObj = {
        ptype_key: parseInt(this.editData[0].part_type[0].value),
        checkbox_name: name,
        checkbox_value: value,
        bcn: this.bcnValue,
        serial_number: this.serialNoCapturedValue,
        ware_key: parseInt(wareKey),
        iw_repair: this.iwRepairValue,
        ow_repair: this.owRepairValue,
        gobi: this.gobiValue,
        esn_imei: this.esnIMEIValue,
        iccid: this.ICCIDCapturedValue,
      };
      let controlUIData = await PartService.postControlUIDetails("post", controlUIObj);
      if (controlUIData[0] !== undefined) {
        if (controlUIData[0].auto_generate_bcn !== undefined) {
          this.autoGenerateBCNValue = controlUIData[0].auto_generate_bcn[0].value;
          this.autoGenerateBCNShowInUI = controlUIData[0].auto_generate_bcn[0].show_in_ui;
          this.autoGenerateBCNEnabled = controlUIData[0].auto_generate_bcn[0].enabled;
        }
        if (controlUIData[0].auto_generated_prefix !== undefined) {
          this.autoGeneratedPrefixValue = controlUIData[0].auto_generated_prefix[0].value;
          this.autoGeneratedPrefixShowInUI = controlUIData[0].auto_generated_prefix[0].show_in_ui;
          this.autoGeneratedPrefixEnabled = controlUIData[0].auto_generated_prefix[0].enabled;
        }
        if (controlUIData[0].gobi !== undefined) {
          this.gobiValue = controlUIData[0].gobi[0].value;
          this.gobiShowInUI = controlUIData[0].gobi[0].show_in_ui;
          this.gobiEnabled = controlUIData[0].gobi[0].enabled;
        }
        if (controlUIData[0].esn_imei !== undefined) {
          this.esnIMEIValue = controlUIData[0].esn_imei[0].value;
          this.esnIMEIShowInUI = controlUIData[0].esn_imei[0].show_in_ui;
          this.esnIMEIEnabled = controlUIData[0].esn_imei[0].enabled;
        }
        if (controlUIData[0].iccid !== undefined) {
          this.ICCIDCapturedValue = controlUIData[0].iccid[0].value;
          this.ICCIDCapturedShowInUI = controlUIData[0].iccid[0].show_in_ui;
          this.ICCIDCapturedEnabled = controlUIData[0].iccid[0].enabled;
        }
        if (controlUIData[0].third_strike_visit !== undefined) {
          this.thirdStrikeValue = controlUIData[0].third_strike_visit[0].value;
          this.thirdStrikeShowInUI = controlUIData[0].third_strike_visit[0].show_in_ui;
          this.thirdStrikeEnabled = controlUIData[0].third_strike_visit[0].enabled;
        }
        if (controlUIData[0].minimum_revision !== undefined) {
          this.minRevLevelValue = controlUIData[0].minimum_revision[0].value;
          this.minRevLevelShowInUI = controlUIData[0].minimum_revision[0].show_in_ui;
          this.minRevLevelEnabled = controlUIData[0].minimum_revision[0].enabled;
        }
      }
    },
    //Substitution Part - Start
    //Substitution Edit Function - Dont remove - CP
    async substitutionPartEdit(item) {
      this.subPartDelete = true;
      this.substitutionSaveDisabled = true;
      this.sequence = 0;
      this.sequence = item.sequence;
      this.substitutionPartListDialog = true;
      this.partNumberSearch = item.part_num;
      this.numberPart = item.part_num;
      this.descriptionPart = item.part_desc;
      this.showPartDetails = true;
      this.partNumSearched = true;
      this.valPartNumFill = false;
      this.saveSubstitutionDet = false;
      this.editSubstitutionDet = true;
      let substitutionObj = {
        UserId: this.userId,
        SearchStr: this.partNumberSearch,
        part_num: "",
        proj_key: this.editData[0].project[0].value,
      };
      let substitutionPathData = await PartService.postSubstitutionPartsList("post", substitutionObj);
      if (substitutionPathData) {
        this.substitutionList = substitutionPathData;
        this.partDetails = false;
      }
      let warehouseData = await PartService.getWarehouseDetails("get", item.sub_part_key);
      if (warehouseData.length !== undefined) {
        this.warehousePartNumList = warehouseData;
      }
      this.partNumWarehouse = item.ware_key;
    },
    //Substitution data table details
    async postSubstitutionPartsList() {
      if (this.partNumberSearch == this.editData[0].part_num[0].value) {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Same Part Number can not be used.",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      } else if (this.partNumberSearch !== "" && this.partNumberSearch !== null) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.partDetails = true;
        let substitutionObj = {
          UserId: this.userId,
          SearchStr: this.partNumberSearch,
          part_num: "",
          proj_key: this.editData[0].project[0].value,
        };
        this.axios
          .post("pe/parts_subpart_search", substitutionObj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              let responseData = JSON.parse(response.data.body.message);
              this.substitutionList = responseData;
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          });
      }
    },
    //Clear Substitutions Part Textbox
    clearSubstitutionParts() {
      this.substitutionList = [];
      this.showPartDetails = false;
      this.numberPart = "";
      this.descriptionPart = "";
      this.partNumWarehouse = "";
      this.partDetails = false;
    },
    //New Substitution Part List
    newSubstitutionPart() {
      this.part_key = "";
      this.numberPart = "";
      this.descriptionPart = "";
      this.partNumberSearch = "";
      this.partNumWarehouse = "";
      this.valPartNumFill = false;
      this.partNumSearched = true;
      this.showPartDetails = false;
      this.substitutionPartListDialog = true;
      this.saveSubstitutionDet = true;
      this.editSubstitutionDet = false;
      this.subPartDelete = false;
    },
    //Save button click event in Substitution Part List
    saveSubstitutionPartInfo() {
      this.substitutionSaveDisabled = true;
      this.substitutionPartListDialog = false;
      let warehouseData = this.warehousePartNumList.filter((obj) => obj.ware_id === this.partNumWarehouse);
      let substitutionObj = {
        sequence: this.listSubstitutionDetails.length + 1,
        sub_part_key: this.part_key,
        part_num: this.numberPart,
        part_desc: this.descriptionPart,
        ware: warehouseData[0].ware,
        ware_key: warehouseData[0].ware_id,
      };
      this.listSubstitutionDetails.push(substitutionObj);
      this.pageChanged = true;
    },
    //Edit Substitution details
    editSubstitutionPartInfo() {
      this.substitutionSaveDisabled = true;
      this.substitutionPartListDialog = false;
      let warehouseData = this.warehousePartNumList.filter((obj) => obj.ware_id === this.partNumWarehouse);
      this.listSubstitutionDetails = this.listSubstitutionDetails.map((x) =>
        x.sequence == this.sequence
          ? {
            ...x,
            part_num: this.numberPart,
            part_desc: this.descriptionPart,
            sub_part_key: this.part_key,
            ware: warehouseData[0].ware,
            ware_key: warehouseData[0].ware_id,
          }
          : x
      );
      this.pageChanged = true;
    },
    //Selected Part Details
    showPartDesc(item) {
      if (item.Part_Number == this.editData[0].part_num[0].value) {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Same Part Number can not be used.",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      } else {
        this.substitutionList = [];
        this.showPartDetails = true;
        this.partNumSearched = false;
        this.partNumWarehouse = "";
        this.numberPart = item.Part_Number;
        this.descriptionPart = item.Description;
        this.part_key = item.part_id;
        this.partNumberSearch = item.Part_Number;
        if (this.listSubstitutionDetails.length > 0) {
          this.subDuplicate = this.listSubstitutionDetails.find((elem) => elem.part_num == this.numberPart);
        }
        if (this.subDuplicate == 0 || this.subDuplicate == undefined) {
          this.getWarehouseDetails(item.part_id);
        } else {
          this.subDuplicate = 0;
          this.showPartDetails = false;
          this.partNumSearched = true;
          this.numberPart = "";
          this.descriptionPart = "";
          this.part_key = "";
          this.partNumberSearch = "";
          let Alert = {
            type: "error",
            isShow: true,
            message: "Sub Part is already used.",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      }
    },
    //Get Warehouse Details for Substitution Part
    async getWarehouseDetails(part_key) {
      let warehouseData = await PartService.getWarehouseDetails("get", part_key);
      if (warehouseData.length !== undefined) {
        this.warehousePartNumList = warehouseData;
        this.partNumSearched = false;
        this.valPartNumFill = true;
      } else {
        this.partNumSearched = true;
        this.valPartNumFill = false;
      }
    },
    //Substitution Part Delete Confirmation popup
    subDeleteDialog() {
      this.substitutionPartListDialog = false;
      this.subPartDelDialog = true;
    },
    //Substitution Part Delete
    deleteSubPart() {
      this.listSubstitutionDetails = this.listSubstitutionDetails.filter((obj) => obj.part_num !== this.numberPart);
      this.subPartDelDialog = false;
    },
    //Substitution Part - End
    //Close button on popups
    closeDialog() {
      this.kitBOMDialog = false;
      this.kitBundleDelDialog = false;
      this.subPartDelDialog = false;
      this.warehouseDelDialog = false;
      this.bucketDialog = false;
      this.bucketItems = [];
      this.bucketDetails = "";
      this.searchBucketDetails = "";
      this.partNumberSearch = "";
      this.partNumWarehouse = "";
      this.numberPart = "";
      this.descriptionPart = "";
      this.valPartNumFill = false;
      this.partNumSearched = true;
      this.showPartDetails = false;
      this.wareSelected = false;
      this.warehouseDialog = false;
      this.substitutionPartListDialog = false;
      this.partDetails = false;
      this.clearDataWarehouse();
      this.clearWarehouseFields();
    },
    //Warehouse - Start
    //Refresh Warehouse Dialog
    refreshWareDialog() {
      this.tab = 0;
      this.showSelectedWare = true;
      this.selectedWarehouse = "";
      this.wareDuplicate = 0;
      this.warehouseDataAvlbl = false;
      this.saveWarehouseData = false;
      this.editWarehouseData = false;
      this.wareSelected = false;
      this.clearDataWarehouse();
    },
    //Refresh for the Edit of Warehouse popup
    refreshEditWareDialog() {
      this.tab = 0;
      this.showSelectedWare = false;
      this.warehouseDataAvlbl = true;
      this.saveWarehouseData = false;
      this.editWarehouseData = true;
      this.clearWarehouseFields();
    },
    //Once Warehouse is selected
    async warehouseSelected() {
      if (this.warehouseList.length > 0) {
        this.wareDuplicate = this.warehouseList.find((elem) => elem.ware_key == this.selectedWarehouse);
      }
      if (this.wareDuplicate == 0 || this.wareDuplicate == undefined) {
        let warehouseSetupObj = {
          ware_key: parseInt(this.selectedWarehouse),
          proj_key: parseInt(this.editData[0].project[0].value),
          ptype_key: parseInt(this.editData[0].part_type[0].value),
          isSerialized: this.serialNoCapturedValue || this.bcnValue,
        };
        let warehouseSetupData = await PartService.postWarehouseSetup("post", warehouseSetupObj);
        if (warehouseSetupData) {
          // this.clearDataWarehouse();
          this.showSelectedWare = false;
          this.wareSelected = true;
          this.warehouseDataAvlbl = true;
          this.saveWarehouseData = true;
          this.editWarehouseData = false;
          this.dataWarehouse[0].sequence = this.warehouseList.length + 1;
          this.dataWarehouse[0].pware_id = 0;
          this.dataWarehouse[0].part_key = 0;
          this.dataWarehouse[0].bu_key = warehouseSetupData[0].bu_key;
          this.dataWarehouse[0].ware_key = this.selectedWarehouse;
          this.dataWarehouse[0].ware[0].show_in_ui = true;
          this.dataWarehouse[0].ware[0].enabled = true;
          this.dataWarehouse[0].ware[0].value = warehouseSetupData[0].ware;
          this.dataWarehouse[0].business[0].show_in_ui = true;
          this.dataWarehouse[0].business[0].enabled = true;
          this.dataWarehouse[0].business[0].value = warehouseSetupData[0].business;
          this.dataWarehouse[0].whse_type[0].show_in_ui = true;
          this.dataWarehouse[0].whse_type[0].enabled = true;
          this.dataWarehouse[0].whse_type[0].value = warehouseSetupData[0].whse_type;
          this.dataWarehouse[0].can_delete = false;
          this.dataWarehouse[0].tab1[0].enabled = warehouseSetupData[0].tab1[0].enabled;
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_owner[0].company = warehouseSetupData[0].tab1[0].prompts[0].whse_owner[0].company;
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_owner[0].address1 = warehouseSetupData[0].tab1[0].prompts[0].whse_owner[0].address1;
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_owner[0].city = warehouseSetupData[0].tab1[0].prompts[0].whse_owner[0].city;
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_owner[0].state = warehouseSetupData[0].tab1[0].prompts[0].whse_owner[0].state;
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_owner[0].country = warehouseSetupData[0].tab1[0].prompts[0].whse_owner[0].country;
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].company = warehouseSetupData[0].tab1[0].prompts[0].whse_location[0].company;
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].address1 = warehouseSetupData[0].tab1[0].prompts[0].whse_location[0].address1;
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].city = warehouseSetupData[0].tab1[0].prompts[0].whse_location[0].city;
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].state = warehouseSetupData[0].tab1[0].prompts[0].whse_location[0].state;
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].country = warehouseSetupData[0].tab1[0].prompts[0].whse_location[0].country;
          this.dataWarehouse[0].tab1[0].fgi[0].show_in_ui = true;
          this.dataWarehouse[0].tab1[0].fgi[0].enabled = true;
          this.dataWarehouse[0].tab1[0].fgi[0].value = 0;
          this.dataWarehouse[0].tab1[0].tbe[0].show_in_ui = true;
          this.dataWarehouse[0].tab1[0].tbe[0].enabled = true;
          this.dataWarehouse[0].tab1[0].tbe[0].value = 0;
          this.dataWarehouse[0].tab1[0].mrb[0].show_in_ui = true;
          this.dataWarehouse[0].tab1[0].mrb[0].enabled = true;
          this.dataWarehouse[0].tab1[0].mrb[0].value = 0;
          this.dataWarehouse[0].tab1[0].scrap[0].show_in_ui = true;
          this.dataWarehouse[0].tab1[0].scrap[0].enabled = true;
          this.dataWarehouse[0].tab1[0].scrap[0].value = 0;
          this.dataWarehouse[0].tab1[0].wip[0].show_in_ui = true;
          this.dataWarehouse[0].tab1[0].wip[0].enabled = true;
          this.dataWarehouse[0].tab1[0].wip[0].value = 0;
          this.dataWarehouse[0].tab1[0].onorder_qty[0].show_in_ui = true;
          this.dataWarehouse[0].tab1[0].onorder_qty[0].enabled = true;
          this.dataWarehouse[0].tab1[0].onorder_qty[0].value = 0;
          this.dataWarehouse[0].tab1[0].allocated[0].show_in_ui = true;
          this.dataWarehouse[0].tab1[0].allocated[0].enabled = true;
          this.dataWarehouse[0].tab1[0].allocated[0].value = 0;
          this.dataWarehouse[0].tab1[0].backordered_qty[0].show_in_ui = true;
          this.dataWarehouse[0].tab1[0].backordered_qty[0].enabled = true;
          this.dataWarehouse[0].tab1[0].backordered_qty[0].value = 0;
          this.dataWarehouse[0].tab1[0].onhand_qty[0].show_in_ui = true;
          this.dataWarehouse[0].tab1[0].onhand_qty[0].enabled = true;
          this.dataWarehouse[0].tab1[0].onhand_qty[0].value = 0;
          this.dataWarehouse[0].tab1[0].available[0].show_in_ui = true;
          this.dataWarehouse[0].tab1[0].available[0].enabled = true;
          this.dataWarehouse[0].tab1[0].available[0].value = 0;
          this.dataWarehouse[0].tab1[0].unit_avg_cost[0].show_in_ui = warehouseSetupData[0].tab1[0].prompts[0].avg_cost;
          this.dataWarehouse[0].tab1[0].unit_avg_cost[0].enabled = true;
          this.dataWarehouse[0].tab1[0].unit_avg_cost[0].value = 0;
          this.dataWarehouse[0].tab1[0].unit_cost_on_latest_po[0].show_in_ui = true;
          this.dataWarehouse[0].tab1[0].unit_cost_on_latest_po[0].enabled = true;
          this.dataWarehouse[0].tab1[0].unit_cost_on_latest_po[0].value = 0;
          this.dataWarehouse[0].tab1[0].lead_time[0].show_in_ui = warehouseSetupData[0].tab1[0].prompts[0].lead_time;
          this.dataWarehouse[0].tab1[0].lead_time[0].enabled = true;
          this.dataWarehouse[0].tab1[0].lead_time[0].value = 0;
          this.dataWarehouse[0].tab1[0].avg_lead_time[0].show_in_ui = true;
          this.dataWarehouse[0].tab1[0].avg_lead_time[0].enabled = true;
          this.dataWarehouse[0].tab1[0].avg_lead_time[0].value = 0;
          this.dataWarehouse[0].tab1[0].dsi[0].show_in_ui = warehouseSetupData[0].tab1[0].prompts[0].dsi;
          this.dataWarehouse[0].tab1[0].dsi[0].enabled = true;
          this.dataWarehouse[0].tab1[0].dsi[0].value = 0;
          this.dataWarehouse[0].tab1[0].daily_usage[0].show_in_ui = true;
          this.dataWarehouse[0].tab1[0].daily_usage[0].enabled = true;
          this.dataWarehouse[0].tab1[0].daily_usage[0].value = 0;
          this.dataWarehouse[0].tab1[0].reorder_point[0].show_in_ui = warehouseSetupData[0].tab1[0].prompts[0].reorder_point;
          this.dataWarehouse[0].tab1[0].reorder_point[0].enabled = true;
          this.dataWarehouse[0].tab1[0].reorder_point[0].value = 0;
          this.dataWarehouse[0].tab1[0].order_point_calc[0].show_in_ui = true;
          this.dataWarehouse[0].tab1[0].order_point_calc[0].enabled = true;
          this.dataWarehouse[0].tab1[0].order_point_calc[0].value = 0;
          this.dataWarehouse[0].tab2[0].enabled = warehouseSetupData[0].tab2[0].enabled;
          this.CRAShowInUI = warehouseSetupData[0].tab2[0].prompts[0].cra;
          this.CRAEnabled = true;
          this.CRAValue = false;
          this.DNCRCVShowInUI = warehouseSetupData[0].tab2[0].prompts[0].dnc_rcv;
          this.DNCRCVEnabled = true;
          this.DNCRCVValue = false;
          this.IFIRShowInUI = warehouseSetupData[0].tab2[0].prompts[0].ifir;
          this.IFIREnabled = true;
          this.IFIRValue = false;
          this.IFIRDaysShowInUI = warehouseSetupData[0].tab2[0].prompts[0].ifir_days;
          this.IFIRDaysEnabled = false;
          this.IFIRDaysValue = 0;
          this.returnsToScrapShowInUI = warehouseSetupData[0].tab2[0].prompts[0].returns_to_scrap;
          this.returnsToScrapEnabled = true;
          this.returnsToScrapValue = false;
          this.DNCBypassScrapShowInUI = warehouseSetupData[0].tab2[0].prompts[0].dnc_bypass_scrap;
          this.DNCBypassScrapEnabled = true;
          this.DNCBypassScrapValue = false;
          this.initialTestTriageShowInUI = warehouseSetupData[0].tab2[0].prompts[0].initial_test;
          this.initialTestTriageEnabled = true;
          this.initialTestTriageValue = false;
          this.iwTestPathShowInUI = warehouseSetupData[0].tab2[0].prompts[0].iw_test_path;
          this.iwTestPathEnabled = false;
          this.iwTestPathValue = false;
          this.owTestPathShowInUI = warehouseSetupData[0].tab2[0].prompts[0].ow_test_path;
          this.owTestPathEnabled = false;
          this.owTestPathValue = false;
          this.finalTestPreShowInUI = warehouseSetupData[0].tab2[0].prompts[0].final_test_pre;
          this.finalTestPreEnabled = true;
          this.finalTestPreValue = false;
          this.owRepairShowInUI = warehouseSetupData[0].tab2[0].prompts[0].ow_repair;
          this.owRepairEnabled = true;
          this.owRepairValue = false;
          this.iwRepairShowInUI = warehouseSetupData[0].tab2[0].prompts[0].iw_repair;
          this.iwRepairEnabled = true;
          this.iwRepairValue = false;
          this.waitingForPartsShowInUI = warehouseSetupData[0].tab2[0].prompts[0].waiting_for_parts;
          this.waitingForPartsEnabled = true;
          this.waitingForPartsValue = false;
          this.firmwareShowInUI = warehouseSetupData[0].tab2[0].prompts[0].firmware;
          this.firmwareEnabled = true;
          this.firmwareValue = false;
          this.finalTestPostShowInUI = warehouseSetupData[0].tab2[0].prompts[0].final_test_post;
          this.finalTestPostEnabled = true;
          this.finalTestPostValue = false;
          this.engineeringEvalShowInUI = warehouseSetupData[0].tab2[0].prompts[0].engineering_eval;
          this.engineeringEvalEnabled = true;
          this.engineeringEvalValue = false;
          this.wipeSanitizeShowInUI = warehouseSetupData[0].tab2[0].prompts[0].wipe_sanitize;
          this.wipeSanitizeEnabled = true;
          this.wipeSanitizeValue = false;
          this.cosmeticInspectionShowInUI = warehouseSetupData[0].tab2[0].prompts[0].cosmetic_insp;
          this.cosmeticInspectionEnabled = true;
          this.cosmeticInspectionValue = false;
          this.auditShowInUI = warehouseSetupData[0].tab2[0].prompts[0].audit;
          this.auditEnabled = true;
          this.auditValue = false;
          this.auditSamplingRateShowInUI = warehouseSetupData[0].tab2[0].prompts[0].audit_sampling_rate;
          this.auditSamplingRateEnabled = true;
          this.auditSamplingRateValue = false;
          this.harvestShowInUI = warehouseSetupData[0].tab2[0].prompts[0].harvest;
          this.harvestEnabled = true;
          this.harvestValue = false;
          this.getTestPathDetails();
        }
      } else {
        this.wareDuplicate = 0;
        let Alert = {
          type: "error",
          isShow: true,
          message: "Warehouse already used.",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Get Test paths
    async getTestPathDetails() {
      let testPathData = await PartService.getTestPathDetails("get");
      this.iwTestPathList = testPathData.iw_test_path;
      this.owTestPathList = testPathData.ow_test_path;
    },
    //New Warehouse button click event
    newWareHouse() {
      this.clearDataWarehouse();
      this.clearWarehouseFields();
      this.anchorTagElements = false;
      this.deleteWarehouse = false;
      this.warehouseDialog = true;
      this.showSelectedWare = true;
      this.wareSelected = false;
      this.warehouseDataAvlbl = false;
      this.saveWarehouseData = false;
      this.editWarehouseData = false;
      this.selectedWarehouse = "";
      this.getWarehouseData();
    },
    //Get Warehouse Details
    async getWarehouseData() {
      let warehouseObj = {
        user_key: parseInt(this.userId),
        proj_key: parseInt(this.editData[0].project[0].value),
        part_key: parseInt(this.partsID),
        ptype_key: parseInt(this.editData[0].part_type[0].value),
      };
      let warehouseData = await PartService.postNewWarehouse("post", warehouseObj);
      this.listWarehouse = warehouseData;
    },
    //Edit Warehouse details
    async warehouseEdit(item) {
      this.clearDataWarehouse();
      this.getTestPathDetails();
      this.tab = 0;
      if (this.warehouseList.length == 1) {
        this.deleteWarehouse = false;
      } else if (this.warehouseList.length > 1) {
        this.deleteWarehouse = true;
      }
      if (item.pware_id > 0) {
        if (!item.isedited) {
          let serialized = this.serialNoCapturedValue || this.bcnValue;
          let partWareHouseData = await PartService.getWareDetails("get", item.pware_id, serialized);
          Object.assign(this.dataWarehouse[0], partWareHouseData[0]);
          this.dataWarehouse[0].sequence = item.sequence;
          this.selectedWarehouse = this.dataWarehouse[0].ware_key;
          this.dataWarehouse[0].pware_id = item.pware_id;
          this.dataWarehouse[0].part_key = this.partsID;
          this.anchorTagElements = true;
          this.showSelectedWare = false;
          this.deleteWarehouse = partWareHouseData[0].can_delete;
          this.CRAValue = partWareHouseData[0].tab2[0].prompts[0].cra[0].value;
          this.DNCRCVValue = partWareHouseData[0].tab2[0].prompts[0].dnc_rcv[0].value;
          this.IFIRValue = partWareHouseData[0].tab2[0].prompts[0].ifir[0].value;
          this.IFIRDaysValue = partWareHouseData[0].tab2[0].prompts[0].ifir_days[0].value;
          this.returnsToScrapValue = partWareHouseData[0].tab2[0].prompts[0].returns_to_scrap[0].value;
          this.DNCBypassScrapValue = partWareHouseData[0].tab2[0].prompts[0].dnc_bypass_scrap[0].value;
          this.initialTestTriageValue = partWareHouseData[0].tab2[0].prompts[0].initial_test[0].value;
          this.iwTestPathValue = partWareHouseData[0].tab2[0].prompts[0].iw_test_path[0].value;
          this.owTestPathValue = partWareHouseData[0].tab2[0].prompts[0].ow_test_path[0].value;
          this.finalTestPreValue = partWareHouseData[0].tab2[0].prompts[0].final_test_pre[0].value;
          this.owRepairValue = partWareHouseData[0].tab2[0].prompts[0].ow_repair[0].value;
          this.iwRepairValue = partWareHouseData[0].tab2[0].prompts[0].iw_repair[0].value;
          this.waitingForPartsValue = partWareHouseData[0].tab2[0].prompts[0].waiting_for_parts[0].value;
          this.firmwareValue = partWareHouseData[0].tab2[0].prompts[0].firmware[0].value;
          this.finalTestPostValue = partWareHouseData[0].tab2[0].prompts[0].final_test_post[0].value;
          this.engineeringEvalValue = partWareHouseData[0].tab2[0].prompts[0].engineering_eval[0].value;
          this.wipeSanitizeValue = partWareHouseData[0].tab2[0].prompts[0].wipe_sanitize[0].value;
          this.cosmeticInspectionValue = partWareHouseData[0].tab2[0].prompts[0].cosmetic_insp[0].value;
          this.auditValue = partWareHouseData[0].tab2[0].prompts[0].audit[0].value;
          this.auditSamplingRateValue = partWareHouseData[0].tab2[0].prompts[0].audit_sampling_rate[0].value;
          this.harvestValue = partWareHouseData[0].tab2[0].prompts[0].harvest[0].value;
          this.CRAShowInUI = partWareHouseData[0].tab2[0].prompts[0].cra[0].show_in_ui;
          this.DNCRCVShowInUI = partWareHouseData[0].tab2[0].prompts[0].dnc_rcv[0].show_in_ui;
          this.IFIRShowInUI = partWareHouseData[0].tab2[0].prompts[0].ifir[0].show_in_ui;
          this.IFIRDaysShowInUI = partWareHouseData[0].tab2[0].prompts[0].ifir_days[0].show_in_ui;
          this.returnsToScrapShowInUI = partWareHouseData[0].tab2[0].prompts[0].returns_to_scrap[0].show_in_ui;
          this.DNCBypassScrapShowInUI = partWareHouseData[0].tab2[0].prompts[0].dnc_bypass_scrap[0].show_in_ui;
          this.initialTestTriageShowInUI = partWareHouseData[0].tab2[0].prompts[0].initial_test[0].show_in_ui;
          this.iwTestPathShowInUI = partWareHouseData[0].tab2[0].prompts[0].iw_test_path[0].show_in_ui;
          this.owTestPathShowInUI = partWareHouseData[0].tab2[0].prompts[0].ow_test_path[0].show_in_ui;
          this.finalTestPreShowInUI = partWareHouseData[0].tab2[0].prompts[0].final_test_pre[0].show_in_ui;
          this.owRepairShowInUI = partWareHouseData[0].tab2[0].prompts[0].ow_repair[0].show_in_ui;
          this.iwRepairShowInUI = partWareHouseData[0].tab2[0].prompts[0].iw_repair[0].show_in_ui;
          this.waitingForPartsShowInUI = partWareHouseData[0].tab2[0].prompts[0].waiting_for_parts[0].show_in_ui;
          this.firmwareShowInUI = partWareHouseData[0].tab2[0].prompts[0].firmware[0].show_in_ui;
          this.finalTestPostShowInUI = partWareHouseData[0].tab2[0].prompts[0].final_test_post[0].show_in_ui;
          this.engineeringEvalShowInUI = partWareHouseData[0].tab2[0].prompts[0].engineering_eval[0].show_in_ui;
          this.cosmeticInspectionShowInUI = partWareHouseData[0].tab2[0].prompts[0].cosmetic_insp[0].show_in_ui;
          this.auditShowInUI = partWareHouseData[0].tab2[0].prompts[0].audit[0].show_in_ui;
          this.auditSamplingRateShowInUI = partWareHouseData[0].tab2[0].prompts[0].audit_sampling_rate[0].show_in_ui;
          this.harvestShowInUI = partWareHouseData[0].tab2[0].prompts[0].harvest[0].show_in_ui;
          this.CRAEnabled = partWareHouseData[0].tab2[0].prompts[0].cra[0].enabled;
          this.DNCRCVEnabled = partWareHouseData[0].tab2[0].prompts[0].dnc_rcv[0].enabled;
          this.IFIREnabled = partWareHouseData[0].tab2[0].prompts[0].ifir[0].enabled;
          this.IFIRDaysEnabled = partWareHouseData[0].tab2[0].prompts[0].ifir_days[0].enabled;
          this.returnsToScrapEnabled = partWareHouseData[0].tab2[0].prompts[0].returns_to_scrap[0].enabled;
          this.DNCBypassScrapEnabled = partWareHouseData[0].tab2[0].prompts[0].dnc_bypass_scrap[0].enabled;
          this.initialTestTriageEnabled = partWareHouseData[0].tab2[0].prompts[0].initial_test[0].enabled;
          this.iwTestPathEnabled = partWareHouseData[0].tab2[0].prompts[0].iw_test_path[0].enabled;
          this.owTestPathEnabled = partWareHouseData[0].tab2[0].prompts[0].ow_test_path[0].enabled;
          this.finalTestPreEnabled = partWareHouseData[0].tab2[0].prompts[0].final_test_pre[0].enabled;
          this.owRepairEnabled = partWareHouseData[0].tab2[0].prompts[0].ow_repair[0].enabled;
          this.iwRepairEnabled = partWareHouseData[0].tab2[0].prompts[0].iw_repair[0].enabled;
          this.waitingForPartsEnabled = partWareHouseData[0].tab2[0].prompts[0].waiting_for_parts[0].enabled;
          this.firmwareEnabled = partWareHouseData[0].tab2[0].prompts[0].firmware[0].enabled;
          this.finalTestPostEnabled = partWareHouseData[0].tab2[0].prompts[0].final_test_post[0].enabled;
          this.engineeringEvalEnabled = partWareHouseData[0].tab2[0].prompts[0].engineering_eval[0].enabled;
          this.wipeSanitizeEnabled = partWareHouseData[0].tab2[0].prompts[0].wipe_sanitize[0].enabled;
          this.cosmeticInspectionEnabled = partWareHouseData[0].tab2[0].prompts[0].cosmetic_insp[0].enabled;
          this.auditEnabled = partWareHouseData[0].tab2[0].prompts[0].audit[0].enabled;
          this.auditSamplingRateEnabled = partWareHouseData[0].tab2[0].prompts[0].audit_sampling_rate[0].enabled;
          this.harvestEnabled = partWareHouseData[0].tab2[0].prompts[0].harvest[0].enabled;
        } else if (item.isedited) {
          this.selectedWarehouse = item.ware_key;
          this.dataWarehouse[0].sequence = item.sequence;
          this.dataWarehouse[0].pware_id = item.wareData.pware_id;
          this.dataWarehouse[0].part_key = this.partsID;
          this.dataWarehouse[0].bu_key = item.wareData.bu_key;
          this.dataWarehouse[0].ware_key = item.wareData.ware;
          this.dataWarehouse[0].ware[0].value = item.wareData.warehouse_data.ware[0].value;
          this.dataWarehouse[0].ware[0].show_in_ui = item.wareData.warehouse_data.ware[0].show_in_ui;
          this.dataWarehouse[0].business[0].show_in_ui = item.wareData.warehouse_data.business[0].show_in_ui;
          this.dataWarehouse[0].business[0].value = item.wareData.business;
          this.dataWarehouse[0].whse_type[0].show_in_ui = item.wareData.warehouse_data.whse_type[0].show_in_ui;
          this.dataWarehouse[0].whse_type[0].value = item.wareData.whse_type;
          this.dataWarehouse[0].can_delete = false;
          //Warehouse field showin ui data
          this.dataWarehouse[0].tab1[0].enabled = item.wareData.warehouse_data.tab1[0].enabled;
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_owner[0].company = item.wareData.warehouse_data.tab1[0].whse_info[0].whse_owner[0].company;
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_owner[0].address1 =
            item.wareData.warehouse_data.tab1[0].whse_info[0].whse_owner[0].address1;
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_owner[0].city = item.wareData.warehouse_data.tab1[0].whse_info[0].whse_owner[0].city;
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_owner[0].state = item.wareData.warehouse_data.tab1[0].whse_info[0].whse_owner[0].state;
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_owner[0].country = item.wareData.warehouse_data.tab1[0].whse_info[0].whse_owner[0].country;
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].company =
            item.wareData.warehouse_data.tab1[0].whse_info[0].whse_owner[0].company;
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].address1 =
            item.wareData.warehouse_data.tab1[0].whse_info[0].whse_owner[0].address1;
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].city = item.wareData.warehouse_data.tab1[0].whse_info[0].whse_owner[0].city;
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].state = item.wareData.warehouse_data.tab1[0].whse_info[0].whse_owner[0].state;
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].country =
            item.wareData.warehouse_data.tab1[0].whse_info[0].whse_owner[0].country;
          this.dataWarehouse[0].tab1[0].unit_avg_cost[0].show_in_ui = item.wareData.warehouse_data.tab1[0].unit_avg_cost[0].show_in_ui;
          this.dataWarehouse[0].tab1[0].lead_time[0].show_in_ui = item.wareData.warehouse_data.tab1[0].lead_time[0].show_in_ui;
          this.dataWarehouse[0].tab1[0].reorder_point[0].show_in_ui = item.wareData.warehouse_data.tab1[0].reorder_point[0].show_in_ui;
          this.dataWarehouse[0].tab1[0].dsi[0].show_in_ui = item.wareData.warehouse_data.tab1[0].dsi[0].show_in_ui;
          this.dataWarehouse[0].tab2[0].enabled = item.wareData.warehouse_data.tab2[0].enabled;
          this.CRAShowInUI = item.wareData.warehouse_data.tab2[0].prompts[0].cra[0].show_in_ui;
          this.DNCRCVShowInUI = item.wareData.warehouse_data.tab2[0].prompts[0].dnc_rcv[0].show_in_ui;
          this.IFIRShowInUI = item.wareData.warehouse_data.tab2[0].prompts[0].ifir[0].show_in_ui;
          this.IFIRDaysShowInUI = item.wareData.warehouse_data.tab2[0].prompts[0].ifir_days[0].show_in_ui;
          this.returnsToScrapShowInUI = item.wareData.warehouse_data.tab2[0].prompts[0].returns_to_scrap[0].show_in_ui;
          this.DNCBypassScrapShowInUI = item.wareData.warehouse_data.tab2[0].prompts[0].dnc_bypass_scrap[0].show_in_ui;
          this.initialTestTriageShowInUI = item.wareData.warehouse_data.tab2[0].prompts[0].initial_test[0].show_in_ui;
          this.iwTestPathShowInUI = item.wareData.warehouse_data.tab2[0].prompts[0].iw_test_path[0].show_in_ui;
          this.owTestPathShowInUI = item.wareData.warehouse_data.tab2[0].prompts[0].ow_test_path[0].show_in_ui;
          this.finalTestPreShowInUI = item.wareData.warehouse_data.tab2[0].prompts[0].final_test_pre[0].show_in_ui;
          this.owRepairShowInUI = item.wareData.warehouse_data.tab2[0].prompts[0].ow_repair[0].show_in_ui;
          this.iwRepairShowInUI = item.wareData.warehouse_data.tab2[0].prompts[0].iw_repair[0].show_in_ui;
          this.waitingForPartsShowInUI = item.wareData.warehouse_data.tab2[0].prompts[0].waiting_for_parts[0].show_in_ui;
          this.firmwareShowInUI = item.wareData.warehouse_data.tab2[0].prompts[0].firmware[0].show_in_ui;
          this.finalTestPostShowInUI = item.wareData.warehouse_data.tab2[0].prompts[0].final_test_post[0].show_in_ui;
          this.engineeringEvalShowInUI = item.wareData.warehouse_data.tab2[0].prompts[0].engineering_eval[0].show_in_ui;
          this.cosmeticInspectionShowInUI = item.wareData.warehouse_data.tab2[0].prompts[0].cosmetic_insp[0].show_in_ui;
          this.auditShowInUI = item.wareData.warehouse_data.tab2[0].prompts[0].audit[0].show_in_ui;
          this.auditSamplingRateShowInUI = item.wareData.warehouse_data.tab2[0].prompts[0].audit_sampling_rate[0].show_in_ui;
          this.harvestShowInUI = item.wareData.warehouse_data.tab2[0].prompts[0].harvest[0].show_in_ui;
          //Warehouse Field Data assigning
          this.dataWarehouse[0].tab1[0].unit_avg_cost[0].value = item.wareData.avg_cost;
          this.dataWarehouse[0].tab1[0].lead_time[0].value = item.wareData.lead_time;
          this.dataWarehouse[0].tab1[0].dsi[0].value = item.wareData.dsi;
          this.dataWarehouse[0].tab1[0].reorder_point[0].value = item.wareData.reorder_point;
          this.CRAValue = item.wareData.cra;
          this.DNCRCVValue = item.wareData.dnc_rcv;
          this.IFIRValue = item.wareData.ifir;
          this.IFIRDaysValue = item.wareData.ifir_days;
          this.returnsToScrapValue = item.wareData.returns_to_scrap;
          this.DNCBypassScrapValue = item.wareData.dnc_bypass_scrap;
          this.initialTestTriageValue = item.wareData.initial_test;
          this.iwTestPathValue = item.wareData.iw_test_path;
          this.owTestPathValue = item.wareData.ow_test_path;
          this.finalTestPreValue = item.wareData.final_test_pre;
          this.owRepairValue = item.wareData.ow_repair;
          this.iwRepairValue = item.wareData.iw_repair;
          this.waitingForPartsValue = item.wareData.waiting_for_parts;
          this.firmwareValue = item.wareData.firmware;
          this.finalTestPostValue = item.wareData.final_test_post;
          this.engineeringEvalValue = item.wareData.engineering_eval;
          this.wipeSanitizeValue = item.wareData.wipe_sanitize;
          this.cosmeticInspectionValue = item.wareData.cosmetic_insp;
          this.auditValue = item.wareData.audit;
          this.auditSamplingRateValue = item.wareData.audit_sampling_rate;
          this.harvestValue = item.wareData.harvest;
        }
        this.warehouseDialog = true;
        this.wareSelected = true;
        this.warehouseDataAvlbl = true;
        this.saveWarehouseData = false;
        this.editWarehouseData = true;
      } else if (item.pware_id == 0) {
        this.selectedWarehouse = item.ware_key;
        this.dataWarehouse[0].sequence = item.sequence;
        this.dataWarehouse[0].pware_id = 0;
        this.dataWarehouse[0].part_key = this.partsID;
        this.dataWarehouse[0].bu_key = item.bu_key;
        this.dataWarehouse[0].ware_key = item.ware;
        this.dataWarehouse[0].ware[0].value = item.warehouse_data.ware[0].value;
        this.dataWarehouse[0].ware[0].show_in_ui = item.warehouse_data.ware[0].show_in_ui;
        this.dataWarehouse[0].business[0].show_in_ui = item.warehouse_data.business[0].show_in_ui;
        this.dataWarehouse[0].business[0].value = item.business;
        this.dataWarehouse[0].whse_type[0].show_in_ui = item.warehouse_data.whse_type[0].show_in_ui;
        this.dataWarehouse[0].whse_type[0].value = item.whse_type;
        this.dataWarehouse[0].can_delete = false;
        //Warehouse field showin ui data
        this.dataWarehouse[0].tab1[0].enabled = item.warehouse_data.tab1[0].enabled;
        this.dataWarehouse[0].tab1[0].whse_info[0].whse_owner[0].company = item.warehouse_data.tab1[0].whse_info[0].whse_owner[0].company;
        this.dataWarehouse[0].tab1[0].whse_info[0].whse_owner[0].address1 = item.warehouse_data.tab1[0].whse_info[0].whse_owner[0].address1;
        this.dataWarehouse[0].tab1[0].whse_info[0].whse_owner[0].city = item.warehouse_data.tab1[0].whse_info[0].whse_owner[0].city;
        this.dataWarehouse[0].tab1[0].whse_info[0].whse_owner[0].state = item.warehouse_data.tab1[0].whse_info[0].whse_owner[0].state;
        this.dataWarehouse[0].tab1[0].whse_info[0].whse_owner[0].country = item.warehouse_data.tab1[0].whse_info[0].whse_owner[0].country;
        this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].company = item.warehouse_data.tab1[0].whse_info[0].whse_owner[0].company;
        this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].address1 = item.warehouse_data.tab1[0].whse_info[0].whse_owner[0].address1;
        this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].city = item.warehouse_data.tab1[0].whse_info[0].whse_owner[0].city;
        this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].state = item.warehouse_data.tab1[0].whse_info[0].whse_owner[0].state;
        this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].country = item.warehouse_data.tab1[0].whse_info[0].whse_owner[0].country;
        this.dataWarehouse[0].tab1[0].unit_avg_cost[0].show_in_ui = item.warehouse_data.tab1[0].unit_avg_cost[0].show_in_ui;
        this.dataWarehouse[0].tab1[0].lead_time[0].show_in_ui = item.warehouse_data.tab1[0].lead_time[0].show_in_ui;
        this.dataWarehouse[0].tab1[0].reorder_point[0].show_in_ui = item.warehouse_data.tab1[0].reorder_point[0].show_in_ui;
        this.dataWarehouse[0].tab1[0].dsi[0].show_in_ui = item.warehouse_data.tab1[0].dsi[0].show_in_ui;
        this.dataWarehouse[0].tab2[0].enabled = item.warehouse_data.tab2[0].enabled;
        this.CRAShowInUI = item.warehouse_data.tab2[0].prompts[0].cra[0].show_in_ui;
        this.DNCRCVShowInUI = item.warehouse_data.tab2[0].prompts[0].dnc_rcv[0].show_in_ui;
        this.IFIRShowInUI = item.warehouse_data.tab2[0].prompts[0].ifir[0].show_in_ui;
        this.IFIRDaysShowInUI = item.warehouse_data.tab2[0].prompts[0].ifir_days[0].show_in_ui;
        this.returnsToScrapShowInUI = item.warehouse_data.tab2[0].prompts[0].returns_to_scrap[0].show_in_ui;
        this.DNCBypassScrapShowInUI = item.warehouse_data.tab2[0].prompts[0].dnc_bypass_scrap[0].show_in_ui;
        this.initialTestTriageShowInUI = item.warehouse_data.tab2[0].prompts[0].initial_test[0].show_in_ui;
        this.iwTestPathShowInUI = item.warehouse_data.tab2[0].prompts[0].iw_test_path[0].show_in_ui;
        this.owTestPathShowInUI = item.warehouse_data.tab2[0].prompts[0].ow_test_path[0].show_in_ui;
        this.finalTestPreShowInUI = item.warehouse_data.tab2[0].prompts[0].final_test_pre[0].show_in_ui;
        this.owRepairShowInUI = item.warehouse_data.tab2[0].prompts[0].ow_repair[0].show_in_ui;
        this.iwRepairShowInUI = item.warehouse_data.tab2[0].prompts[0].iw_repair[0].show_in_ui;
        this.waitingForPartsShowInUI = item.warehouse_data.tab2[0].prompts[0].waiting_for_parts[0].show_in_ui;
        this.firmwareShowInUI = item.warehouse_data.tab2[0].prompts[0].firmware[0].show_in_ui;
        this.finalTestPostShowInUI = item.warehouse_data.tab2[0].prompts[0].final_test_post[0].show_in_ui;
        this.engineeringEvalShowInUI = item.warehouse_data.tab2[0].prompts[0].engineering_eval[0].show_in_ui;
        this.cosmeticInspectionShowInUI = item.warehouse_data.tab2[0].prompts[0].cosmetic_insp[0].show_in_ui;
        this.auditShowInUI = item.warehouse_data.tab2[0].prompts[0].audit[0].show_in_ui;
        this.auditSamplingRateShowInUI = item.warehouse_data.tab2[0].prompts[0].audit_sampling_rate[0].show_in_ui;
        this.harvestShowInUI = item.warehouse_data.tab2[0].prompts[0].harvest[0].show_in_ui;
        //Warehouse Field Data assigning
        this.dataWarehouse[0].tab1[0].unit_avg_cost[0].value = item.avg_cost;
        this.dataWarehouse[0].tab1[0].lead_time[0].value = item.lead_time;
        this.dataWarehouse[0].tab1[0].dsi[0].value = item.dsi;
        this.dataWarehouse[0].tab1[0].reorder_point[0].value = item.reorder_point;
        this.CRAValue = item.cra;
        this.DNCRCVValue = item.dnc_rcv;
        this.IFIRValue = item.ifir;
        this.IFIRDaysValue = item.ifir_days;
        this.returnsToScrapValue = item.returns_to_scrap;
        this.DNCBypassScrapValue = item.dnc_bypass_scrap;
        this.initialTestTriageValue = item.initial_test;
        this.iwTestPathValue = item.iw_test_path;
        this.owTestPathValue = item.ow_test_path;
        this.finalTestPreValue = item.final_test_pre;
        this.owRepairValue = item.ow_repair;
        this.iwRepairValue = item.iw_repair;
        this.waitingForPartsValue = item.waiting_for_parts;
        this.firmwareValue = item.firmware;
        this.finalTestPostValue = item.final_test_post;
        this.engineeringEvalValue = item.engineering_eval;
        this.wipeSanitizeValue = item.wipe_sanitize;
        this.cosmeticInspectionValue = item.cosmetic_insp;
        this.auditValue = item.audit;
        this.auditSamplingRateValue = item.audit_sampling_rate;
        this.harvestValue = item.harvest;
        this.warehouseDialog = true;
        this.wareSelected = true;
        this.warehouseDataAvlbl = true;
        this.saveWarehouseData = false;
        this.editWarehouseData = true;
      }
    },
    //Save Warehouse Info
    saveWarehouseInfo() {
      if (this.warehouseList.length > 0) {
        this.searchDisabled = false;
      }
      this.wareDuplicate = 0;
      let sequence = this.warehouseList.length + 1;
      let warehouseListObj = {
        sequence: sequence,
        pware_id: 0,
        ware_key: this.selectedWarehouse,
        bu_key: this.dataWarehouse[0].business[0].value,
        ware: this.dataWarehouse[0].ware[0].value,
        business: this.dataWarehouse[0].business[0].value,
        whse_type: this.dataWarehouse[0].whse_type[0].value,
        whse_owner: this.dataWarehouse[0].tab1[0].whse_info[0].whse_owner,
        whse_location: this.dataWarehouse[0].tab1[0].whse_info[0].whse_location,
        location:
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].city +
          " ," +
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].country +
          " ," +
          this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].state,
        warehouse_data: this.dataWarehouse[0],
        avg_cost: this.dataWarehouse[0].tab1[0].unit_avg_cost[0].value,
        dsi: this.dataWarehouse[0].tab1[0].dsi[0].value,
        lead_time: this.dataWarehouse[0].tab1[0].lead_time[0].value,
        reorder_point: this.dataWarehouse[0].tab1[0].reorder_point[0].value,
        cra: this.CRAValue,
        initial_test: this.initialTestTriageValue,
        dnc_rcv: this.DNCRCVValue,
        iw_test_path: this.iwTestPathValue,
        ow_test_path: this.owTestPathValue,
        ifir: this.IFIRValue,
        final_test_pre: this.finalTestPreValue,
        ifir_days: this.IFIRDaysValue,
        ow_repair: this.owRepairValue,
        iw_repair: this.iwRepairValue,
        returns_to_scrap: this.returnsToScrapValue,
        waiting_for_parts: this.waitingForPartsValue,
        dnc_bypass_scrap: this.DNCBypassScrapValue,
        firmware: this.firmwareValue,
        final_test_post: this.finalTestPostValue,
        engineering_eval: this.engineeringEvalValue,
        wipe_sanitize: this.wipeSanitizeValue,
        cosmetic_insp: this.cosmeticInspectionValue,
        audit: this.auditValue,
        audit_sampling_rate: this.auditSamplingRateValue,
        harvest: this.harvestValue,
      };
      this.warehouseDialog = false;
      this.warehouseList.push(warehouseListObj);
      this.clearDataWarehouse();
    },
    //Save Edit details
    saveEditWarehouseInfo() {
      if (this.dataWarehouse[0].pware_id == 0) {
        this.warehouseList = this.warehouseList.map((x) =>
          x.ware_key == this.selectedWarehouse && x.sequence == this.dataWarehouse[0].sequence
            ? {
              ...x,
              bu_key: this.dataWarehouse[0].bu_key,
              ware: this.dataWarehouse[0].ware[0].value,
              business: this.dataWarehouse[0].business[0].value,
              whse_type: this.dataWarehouse[0].whse_type[0].value,
              whse_owner: this.dataWarehouse[0].tab1[0].whse_info[0].whse_owner,
              whse_location: this.dataWarehouse[0].tab1[0].whse_info[0].whse_location,
              location:
                this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].city +
                " ," +
                this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].country +
                " ," +
                this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].state,
              warehouse_data: this.dataWarehouse[0],
              avg_cost: this.dataWarehouse[0].tab1[0].unit_avg_cost[0].value,
              dsi: this.dataWarehouse[0].tab1[0].dsi[0].value,
              lead_time: this.dataWarehouse[0].tab1[0].lead_time[0].value,
              reorder_point: this.dataWarehouse[0].tab1[0].reorder_point[0].value,
              cra: this.CRAValue,
              initial_test: this.initialTestTriageValue,
              dnc_rcv: this.DNCRCVValue,
              iw_test_path: this.iwTestPathValue,
              ow_test_path: this.owTestPathValue,
              ifir: this.IFIRValue,
              final_test_pre: this.finalTestPreValue,
              ifir_days: this.IFIRDaysValue,
              ow_repair: this.owRepairValue,
              iw_repair: this.iwRepairValue,
              returns_to_scrap: this.returnsToScrapValue,
              waiting_for_parts: this.waitingForPartsValue,
              dnc_bypass_scrap: this.DNCBypassScrapValue,
              firmware: this.firmwareValue,
              final_test_post: this.finalTestPostValue,
              engineering_eval: this.engineeringEvalValue,
              cosmetic_insp: this.cosmeticInspectionValue,
              audit: this.auditValue,
              audit_sampling_rate: this.auditSamplingRateValue,
              harvest: this.harvestValue,
            }
            : x
        );
      } else if (this.dataWarehouse[0].pware_id > 0) {
        let warehouseListObj = {
          pware_id: this.dataWarehouse[0].pware_id,
          ware_key: this.selectedWarehouse,
          bu_key: this.dataWarehouse[0].business[0].value,
          ware: this.dataWarehouse[0].ware[0].value,
          business: this.dataWarehouse[0].business[0].value,
          whse_type: this.dataWarehouse[0].whse_type[0].value,
          whse_owner: this.dataWarehouse[0].tab1[0].whse_info[0].whse_owner,
          whse_location: this.dataWarehouse[0].tab1[0].whse_info[0].whse_location,
          location:
            this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].city +
            " ," +
            this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].country +
            " ," +
            this.dataWarehouse[0].tab1[0].whse_info[0].whse_location[0].state,
          warehouse_data: this.dataWarehouse[0],
          avg_cost: this.dataWarehouse[0].tab1[0].unit_avg_cost[0].value,
          dsi: this.dataWarehouse[0].tab1[0].dsi[0].value,
          lead_time: this.dataWarehouse[0].tab1[0].lead_time[0].value,
          reorder_point: this.dataWarehouse[0].tab1[0].reorder_point[0].value,
          cra: this.CRAValue,
          initial_test: this.initialTestTriageValue,
          dnc_rcv: this.DNCRCVValue,
          iw_test_path: this.iwTestPathValue,
          ow_test_path: this.owTestPathValue,
          ifir: this.IFIRValue,
          final_test_pre: this.finalTestPreValue,
          ifir_days: this.IFIRDaysValue,
          ow_repair: this.owRepairValue,
          iw_repair: this.iwRepairValue,
          returns_to_scrap: this.returnsToScrapValue,
          waiting_for_parts: this.waitingForPartsValue,
          dnc_bypass_scrap: this.DNCBypassScrapValue,
          firmware: this.firmwareValue,
          final_test_post: this.finalTestPostValue,
          engineering_eval: this.engineeringEvalValue,
          wipe_sanitize: this.wipeSanitizeValue,
          cosmetic_insp: this.cosmeticInspectionValue,
          audit: this.auditValue,
          audit_sampling_rate: this.auditSamplingRateValue,
          harvest: this.harvestValue,
        };
        this.warehouseList.find((element) => element.pware_id == this.dataWarehouse[0].pware_id).wareData = warehouseListObj;
        this.warehouseList.find((element) => element.pware_id == this.dataWarehouse[0].pware_id).isedited = true;
      }
      this.warehouseDialog = false;
      this.clearDataWarehouse();
    },
    //API for validation of checkboxes in Warehouse popup Routing
    async changeWarehouseRouting(name, value) {
      let controlUIObj = {
        ptype_key: parseInt(this.editData[0].part_type[0].value),
        checkbox_name: name,
        checkbox_value: value,
        bcn: this.bcnValue,
        serial_number: this.serialNoCapturedValue,
        ware_key: parseInt(this.selectedWarehouse),
        iw_repair: this.iwRepairValue,
        ow_repair: this.owRepairValue,
        gobi: this.gobiValue,
        esn_imei: this.esnIMEIValue,
        iccid: this.ICCIDCapturedValue,
      };
      let controlUIData = await PartService.postControlUIDetails("post", controlUIObj);
      if (controlUIData[0] !== undefined) {
        if (controlUIData[0].dnc_bypass_scrap !== undefined) {
          this.DNCBypassScrapValue = controlUIData[0].dnc_bypass_scrap[0].value;
          this.DNCRCVShowInUI = controlUIData[0].dnc_bypass_scrap[0].show_in_ui;
          this.DNCRCVEnabled = controlUIData[0].dnc_bypass_scrap[0].enabled;
        }
        if (controlUIData[0].ifir_days !== undefined) {
          this.IFIRDaysValue = controlUIData[0].ifir_days[0].value;
          this.IFIRDaysShowInUI = controlUIData[0].ifir_days[0].show_in_ui;
          this.IFIRDaysEnabled = controlUIData[0].ifir_days[0].enabled;
        }
        if (controlUIData[0].ifir !== undefined) {
          this.IFIRValue = controlUIData[0].ifir[0].value;
          this.IFIRShowInUI = controlUIData[0].ifir[0].show_in_ui;
          this.IFIREnabled = controlUIData[0].ifir[0].enabled;
        }
        if (controlUIData[0].cra !== undefined) {
          this.CRAValue = controlUIData[0].cra[0].value;
          this.CRAShowInUI = controlUIData[0].cra[0].show_in_ui;
          this.CRAEnabled = controlUIData[0].cra[0].enabled;
        }
        if (controlUIData[0].initial_test !== undefined) {
          this.initialTestTriageValue = controlUIData[0].initial_test[0].value;
          this.initialTestTriageShowInUI = controlUIData[0].initial_test[0].show_in_ui;
          this.initialTestTriageEnabled = controlUIData[0].initial_test[0].enabled;
        }
        if (controlUIData[0].iw_test_path !== undefined) {
          this.iwTestPathValue = controlUIData[0].iw_test_path[0].value;
          this.iwTestPathShowInUI = controlUIData[0].iw_test_path[0].show_in_ui;
          this.iwTestPathEnabled = controlUIData[0].iw_test_path[0].enabled;
        }
        if (controlUIData[0].ow_test_path !== undefined) {
          this.owTestPathValue = controlUIData[0].ow_test_path[0].value;
          this.owTestPathShowInUI = controlUIData[0].ow_test_path[0].show_in_ui;
          this.owTestPathEnabled = controlUIData[0].ow_test_path[0].enabled;
        }
        if (controlUIData[0].final_test_pre !== undefined) {
          this.finalTestPreValue = controlUIData[0].final_test_pre[0].value;
          this.finalTestPreShowInUI = controlUIData[0].final_test_pre[0].show_in_ui;
          this.finalTestPreEnabled = controlUIData[0].final_test_pre[0].enabled;
        }
        if (controlUIData[0].iw_repair !== undefined) {
          this.iwRepairValue = controlUIData[0].iw_repair[0].value;
          this.iwRepairShowInUI = controlUIData[0].iw_repair[0].show_in_ui;
          this.iwRepairEnabled = controlUIData[0].iw_repair[0].enabled;
        }
        if (controlUIData[0].ow_repair !== undefined) {
          this.owRepairValue = controlUIData[0].ow_repair[0].value;
          this.owRepairShowInUI = controlUIData[0].ow_repair[0].show_in_ui;
          this.owRepairEnabled = controlUIData[0].ow_repair[0].enabled;
        }
        if (controlUIData[0].final_test_post !== undefined) {
          this.finalTestPostValue = controlUIData[0].final_test_post[0].value;
          this.finalTestPostShowInUI = controlUIData[0].final_test_post[0].show_in_ui;
          this.finalTestPostEnabled = controlUIData[0].final_test_post[0].enabled;
        }
        if (controlUIData[0].engineering_eval !== undefined) {
          this.engineeringEvalValue = controlUIData[0].engineering_eval[0].value;
          this.engineeringEvalShowInUI = controlUIData[0].engineering_eval[0].show_in_ui;
          this.engineeringEvalEnabled = controlUIData[0].engineering_eval[0].enabled;
        }
        if (controlUIData[0].wipe_sanitize !== undefined) {
          this.wipeSanitizeValue = controlUIData[0].wipe_sanitize[0].value;
          this.wipeSanitizeShowInUI = controlUIData[0].wipe_sanitize[0].show_in_ui;
          this.wipeSanitizeEnabled = controlUIData[0].wipe_sanitize[0].enabled;
        }
        if (controlUIData[0].cosmetic_insp !== undefined) {
          this.cosmeticInspectionValue = controlUIData[0].cosmetic_insp[0].value;
          this.cosmeticInspectionShowInUI = controlUIData[0].cosmetic_insp[0].show_in_ui;
          this.cosmeticInspectionEnabled = controlUIData[0].cosmetic_insp[0].enabled;
        }
        if (controlUIData[0].audit !== undefined) {
          this.auditValue = controlUIData[0].audit[0].value;
          this.auditShowInUI = controlUIData[0].audit[0].show_in_ui;
          this.auditEnabled = controlUIData[0].audit[0].enabled;
        }
        if (controlUIData[0].audit_sampling_rate !== undefined) {
          this.auditSamplingRateValue = controlUIData[0].audit_sampling_rate[0].value;
          this.auditSamplingRateShowInUI = controlUIData[0].audit_sampling_rate[0].show_in_ui;
          this.auditSamplingRateEnabled = controlUIData[0].audit_sampling_rate[0].enabled;
        }
        if (controlUIData[0].waiting_for_parts !== undefined) {
          this.waitingForPartsValue = controlUIData[0].waiting_for_parts[0].value;
          this.waitingForPartsShowInUI = controlUIData[0].waiting_for_parts[0].show_in_ui;
          this.waitingForPartsEnabled = controlUIData[0].waiting_for_parts[0].enabled;
        }
        if (controlUIData[0].firmware !== undefined) {
          this.firmwareValue = controlUIData[0].firmware[0].value;
          this.firmwareShowInUI = controlUIData[0].firmware[0].show_in_ui;
          this.firmwareEnabled = controlUIData[0].firmware[0].enabled;
        }
      }
    },
    //Delete warehouse confirmation popup
    deleteDialog() {
      this.warehouseDelDialog = true;
      this.warehouseDialog = false;
    },
    //Delete Warehouse Dialog
    wareDelete() {
      this.warehouseList = this.warehouseList.filter((obj) => obj.ware_key !== this.selectedWarehouse);
      if (this.warehouseList.length == 1) {
        this.searchDisabled = true;
        this.search = "";
      }
      this.warehouseDelDialog = false;
    },
    //On Click on the hyperlink
    async bucketClicked(bucket) {
      let bucketData = await PartService.getPartsBucketQty("get", this.partsID, this.selectedWarehouse, bucket);
      if (bucketData.length !== undefined) {
        this.bucketDialog = true;
        this.bucketItems = bucketData;
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Bins are not available for this bucket",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Warehouse - End
    //Kit/Bundle - Start
    //New Kit/Bundle
    newMemberPart() {
      this.editKitBundle = false;
      this.part_key = "";
      this.kitSearch = "";
      this.kitBundleDetails = false;
      this.kitBundleList = [];
      this.showKitBundleDetails = false;
      this.kitSearched = true;
      this.kitBundlePartDis = true;
      this.numberPart = "";
      this.descriptionPart = "";
      this.kitBundleQty = 1;
      this.kitBundleActive = false;
      this.kitBundlePrimaryPart = false;
      this.kitBundleAddOn = false;
      this.kitBOMDialog = true;
      this.saveKitBundle = true;
    },


    //Existing Kit Delete
    kitDeleteDialog() {
      this.kitBOMDialog = false;
      this.kitBundleDelDialog = true;
    },
    //Delete Kit
    deleteKitBundle() {
      this.listMemberPartDetails = this.listMemberPartDetails.filter((obj) => obj.member_part_key !== this.memberPartKey);
      this.kitBundleDelDialog = false;
    },
    //Kit Data Table Details
    async postKitBundleList() {
      this.kitBundleDetails = true;
      this.kitBundleList = [];
      this.showKitBundleDetails = false;
      this.numberPart = "";
      this.descriptionPart = "";
      this.kitBundleQty = 1;
      this.kitBundleActive = false;
      this.kitBundlePrimaryPart = false;
      this.kitBundleAddOn = false;
      let kitBundleObj = {
        UserId: this.userId,
        SearchStr: this.kitSearch,
        part_num: "",
        proj_key: this.editData[0].project[0].value,
      };
      let kitBundleData = await PartService.postSubstitutionPartsList("post", kitBundleObj);
      if (kitBundleData) {
        this.kitBundleList = kitBundleData;
      }
    },
    //Selected Part Details
    showKitDesc(item) {
      this.kitBundleList = [];
      this.showKitBundleDetails = true;
      this.numberPart = item.Part_Number;
      this.descriptionPart = item.Description;
      this.part_key = item.part_id;
      this.kitSearch = item.Part_Number;
      if (this.listMemberPartDetails.length > 0) {
        this.kitDuplicate = this.listMemberPartDetails.find((elem) => elem.part_num == this.numberPart);
      }
      if (this.kitDuplicate == 0 || this.kitDuplicate == undefined) {
        this.kitSearched = false;
        this.kitBundlePartDis = false;
        if (this.listMemberPartDetails.length > 0) {
          let primaryPart = this.listMemberPartDetails.filter((obj) => obj.primary_pn == true);
          if (primaryPart.length > 0) {
            this.kitBundlePartDis = true;
          }
        }
        if (this.editData[0].project[0].value == 89) {
          this.projectBrinks = true;
        } else {
          this.projectBrinks = false;
        }
      } else {
        this.kitDuplicate = 0;
        this.showKitBundleDetails = false;
        this.numberPart = "";
        this.descriptionPart = "";
        this.part_key = "";
        this.kitSearch = "";
        let Alert = {
          type: "error",
          isShow: true,
          message: "Part is already used.",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Edit Kit/Bundle
    memberPartEdit(item) {
      this.kitSearched = false;
      this.kitBundlePartDis = false;
      this.kitPartSearch = true;
      this.kitBundleDelete = true;
      this.kitBOMDialog = true;
      this.kitSearch = item.part_num;
      this.showKitBundleDetails = true;
      this.numberPart = item.part_num;
      this.descriptionPart = item.part_desc;
      this.kitBundleQty = item.qty;
      this.kitBundleActive = item.active;
      this.kitBundlePrimaryPart = item.primary_pn;
      this.kitBundleAddOn = item.add_on;
      this.editKitBundle = true;
      this.saveKitBundle = false;
      this.memberPartKey = item.member_part_key;
    },
    //Clear Substitutions Part Textbox
    clearKitBundleList() {
      this.kitBundleList = [];
      this.showKitBundleDetails = false;
      this.numberPart = "";
      this.descriptionPart = "";
      this.kitBundleDetails = false;
    },
    //Save Kit Bundle Information
    saveKitBundleInfo() {
      this.kitBOMDialog = false;
      let kitBundleObj = {
        member_part_key: parseInt(this.part_key),
        part_num: this.numberPart,
        part_desc: this.descriptionPart,
        qty: parseInt(this.kitBundleQty),
        primary_pn: this.kitBundlePrimaryPart,
        active: this.kitBundleActive,
        add_on: this.kitBundleAddOn,
      };
      if (this.kitBundlePrimaryPart == true) {
        this.kitBundlePartDis = true;
      }
      this.listMemberPartDetails.push(kitBundleObj);
      this.pageChanged = true;
    },
    //Edit Kit Bundle Information
    editKitBundleInfo() {
      this.kitBOMDialog = false;
      this.listMemberPartDetails = this.listMemberPartDetails.map((x) =>
        x.member_part_key == this.memberPartKey
          ? {
            ...x,
            part_num: this.numberPart,
            part_desc: this.descriptionPart,
            qty: parseInt(this.kitBundleQty),
            primary_pn: this.kitBundlePrimaryPart,
            active: this.kitBundleActive,
            add_on: this.kitBundleAddOn,
          }
          : x
      );
      this.pageChanged = true;
    },
    //Kit/Bundle - End

    //Build options change
    buildOptionsChange() {
      this.pageChanged = true;
      this.buildOptions = [];
      if (this.editData[0].build_options[0].build_selections.length > 0) {
        for (let i = 0; i < this.editData[0].build_options[0].build_selections.length; i++) {
          let buildOption = this.buildOptionsList.filter((obj) => obj.list_id === this.editData[0].build_options[0].build_selections[i]);
          let obj = {
            sequence: i + 1,
            build_option: buildOption[0].displaystring,
            list_id: this.editData[0].build_options[0].build_selections[i],
          };
          if (this.editData[0].build_options[0].build_selections[i] !== undefined) {
            this.buildOptions.push(obj);
          }
        }
      }
    },
    //Model Change
    modelChange() {
      if (this.editData[0].model[0].value["model"]) {
        this.partJSON[0].model = this.editData[0].model[0].value["model"];
      } else {
        this.partJSON[0].model = this.editData[0].model[0].value;
      }
    },
    //OEM Model Change
    oemModelChange() {
      if (this.editData[0].oem_model[0].value["model"]) {
        this.partJSON[0].oem_model = this.editData[0].oem_model[0].value["model"];
      } else {
        this.partJSON[0].oem_model = this.editData[0].oem_model[0].value;
      }
    },
    //Final Submit API
    submitPartEditDetails() {
      if (this.$refs.partsEditUpdateForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.partJSON[0].part_id = this.partsID;
        this.partJSON[0].proj_key = parseInt(this.editData[0].project[0].value);
        this.partJSON[0].part_number = this.editData[0].part_num[0].value;
        this.partJSON[0].part_description = this.editData[0].part_desc[0].value;
        this.partJSON[0].active = true;
        this.partJSON[0].ptype_key = parseInt(this.editData[0].part_type[0].value);
        this.partJSON[0].class_key = parseInt(this.editData[0].part_class[0].value);
        this.partJSON[0].oem_key = parseInt(this.editData[0].oem[0].value);
        this.partJSON[0].odm_key = parseInt(this.editData[0].odm[0].value);
        this.partJSON[0].oem_part = this.oemPartNum;
        this.partJSON[0].uom_key = parseInt(this.editData[0].unit_of_measure[0].value);
        this.partJSON[0].bin_type_key = parseInt(this.editData[0].bin_type[0].value);
        this.partJSON[0].bin_limit = parseInt(this.editData[0].bin_limit[0].value);
        this.partJSON[0].packout_ref = this.editData[0].packout_ref[0].value;
        this.partJSON[0].box_label_size_key = parseInt(this.editData[0].box_label_size[0].value);
        this.partJSON[0].height = parseInt(this.editData[0].height[0].value);
        this.partJSON[0].width = parseInt(this.editData[0].width[0].value);
        this.partJSON[0].length = parseInt(this.editData[0].length[0].value);
        this.partJSON[0].weight = parseInt(this.editData[0].weight[0].value);
        this.partJSON[0].unit_cost = parseFloat(this.editData[0].unit_cost[0].value);
        this.partJSON[0].sale_price = parseFloat(this.editData[0].sale_price[0].value);
        this.partJSON[0].exchange_price = parseFloat(this.editData[0].exchange_price[0].value);
        this.partJSON[0].repair_price = parseFloat(this.editData[0].repair_price[0].value);
        this.partJSON[0].so_warranty_days = parseInt(this.editData[0].so_warranty_days[0].value);
        this.partJSON[0].fgi_dsi_cutoff = parseInt(this.editData[0].fgi_dsi_cutoff[0].value);
        this.partJSON[0].oem_dsi_cutoff = parseInt(this.editData[0].oem_dsi_cutoff[0].value);
        this.partJSON[0].oem_parts_per_skid = parseInt(this.editData[0].oem_parts_per_skid[0].value);
        this.partJSON[0].cf_buffer_qty = parseInt(this.editData[0].cf_buffer_qty[0].value);
        this.partJSON[0].sales_orders = this.enableSalesOrderValue;
        this.partJSON[0].so_straight_sale = this.soStraightSaleValue;
        this.partJSON[0].so_advanced_exchange = this.soAdvancedExchangeValue;
        this.partJSON[0].so_exchange = this.soExchangeValue;
        this.partJSON[0].so_customer_repair = this.soCustRepairValue;
        this.partJSON[0].so_straight_return = this.soStraightReturnValue;
        this.partJSON[0].purchase_orders = this.enablePurchaseOrdersValue;
        this.partJSON[0].po_straight_purchase = this.poStraightPurchaseValue;
        this.partJSON[0].po_advanced_exchange = this.poAdvanceExchangeValue;
        this.partJSON[0].po_exchange = this.poExchangeValue;
        this.partJSON[0].po_stock_repair = this.poStockRepairValue;
        this.partJSON[0].po_return = this.poPOReturnValue;
        this.partJSON[0].bcn = this.bcnValue;
        this.partJSON[0].auto_generate_bcn = this.autoGenerateBCNValue;
        this.partJSON[0].auto_generated_prefix = this.autoGeneratedPrefixValue;
        this.partJSON[0].serial_number = this.serialNoCapturedValue;
        this.partJSON[0].gobi = this.gobiValue;
        this.partJSON[0].esn_imei = this.esnIMEIValue;
        this.partJSON[0].iccid = this.ICCIDCapturedValue;
        this.partJSON[0].box_label = this.boxLabelValue;
        this.partJSON[0].print_comment_sheet = this.printCommentSheetValue;
        this.partJSON[0].prompt_for_new = this.promptForNewProductValue;
        this.partJSON[0].third_strike = this.activateStrikeRuleValue;
        this.partJSON[0].third_strike_visit = this.thirdStrikeValue;
        this.partJSON[0].return_waybill_required = this.returnWaybillLabelRequiredValue;
        this.partJSON[0].revision_level = this.promptRevLevelValue;
        this.partJSON[0].minimum_revision = this.minRevLevelValue;
        this.partJSON[0].firmware_revision = this.promptFirmLevelValue;
        this.partJSON[0].country_id = parseInt(this.selectedCountry);
        this.partJSON[0].size = parseInt(this.displaySizeVal);
        //Build Order Values
        this.partJSON[0].build = this.buildOptions;
        //Cosmetic Inspection Kitting Value
        this.partJSON[0].kit = this.editData[0].kitting[0].value;
        //ECO Information Value
        this.partJSON[0].eco = this.editData[0].eco[0].value;
        //Warehouse Value
        this.partJSON[0].warehouse = this.warehouseList.map((el) => {
          if (el.pware_id > 0 && el.isedited == false) {
            return {
              sequence: el.sequence,
              pware_id: el.pware_id,
              isedited: el.isedited,
            };
          } else if (el.pware_id > 0 && el.isedited == true) {
            return {
              sequence: el.sequence,
              pware_key: el.pware_id,
              part_key: parseInt(this.partsID),
              isedited: el.isedited,
              fgi: el.fgi,
              onorder_qty: el.onorder_qty,
              tbe: el.tbe,
              allocated: el.allocated,
              mrb: el.mrb,
              backordered_qty: el.backordered_qty,
              scrap: el.scrap,
              onhand_qty: el.onhand_qty,
              wip: el.wip,
              available: el.available,
              ware_key: parseInt(el.ware_key),
              avg_cost: parseInt(el.wareData.avg_cost),
              lead_time: parseInt(el.wareData.lead_time),
              dsi: parseInt(el.wareData.dsi),
              reorder_point: parseInt(el.wareData.reorder_point),
              cra: el.wareData.cra,
              dnc_rcv: el.wareData.dnc_rcv,
              ifir: el.wareData.ifir,
              ifir_days: parseInt(el.wareData.ifir_days),
              returns_to_scrap: el.wareData.returns_to_scrap,
              dnc_bypass_scrap: el.wareData.dnc_bypass_scrap,
              initial_test: el.wareData.initial_test,
              iw_test_path_key: parseInt(el.wareData.iw_test_path),
              ow_test_path_key: parseInt(el.wareData.ow_test_path),
              final_test_pre: el.wareData.final_test_pre,
              ow_repair: el.wareData.ow_repair,
              iw_repair: el.wareData.iw_repair,
              waiting_for_parts: el.wareData.waiting_for_parts,
              firmware: el.wareData.firmware,
              final_test_post: el.wareData.final_test_post,
              engineering_eval: el.wareData.engineering_eval,
              wipe_sanitize: el.wareData.wipe_sanitize,
              cosmetic_insp: el.wareData.cosmetic_insp,
              audit: el.wareData.audit,
              audit_sampling_rate: parseInt(el.wareData.audit_sampling_rate),
              harvest: el.wareData.harvest,
            };
          } else if (el.pware_id == 0) {
            return {
              sequence: el.sequence,
              pware_key: el.pware_id,
              part_key: parseInt(this.partsID),
              isedited: false,
              ware_key: parseInt(el.ware_key),
              avg_cost: parseInt(el.avg_cost),
              lead_time: parseInt(el.lead_time),
              dsi: parseInt(el.dsi),
              reorder_point: parseInt(el.reorder_point),
              cra: el.cra,
              dnc_rcv: el.dnc_rcv,
              ifir: el.ifir,
              ifir_days: parseInt(el.ifir_days),
              returns_to_scrap: el.returns_to_scrap,
              dnc_bypass_scrap: el.dnc_bypass_scrap,
              initial_test: el.initial_test,
              iw_test_path_key: parseInt(el.iw_test_path),
              ow_test_path_key: parseInt(el.ow_test_path),
              final_test_pre: el.final_test_pre,
              ow_repair: el.ow_repair,
              iw_repair: el.iw_repair,
              waiting_for_parts: el.waiting_for_parts,
              firmware: el.firmware,
              final_test_post: el.final_test_post,
              engineering_eval: el.engineering_eval,
              wipe_sanitize: el.wipe_sanitize,
              cosmetic_insp: el.cosmetic_insp,
              audit: el.audit,
              audit_sampling_rate: parseInt(el.audit_sampling_rate),
              harvest: el.harvest,
            };
          }
        });
        for (let i = 0; i < this.partJSON[0].warehouse.length; i++) {
          this.partJSON[0].warehouse[i].sequence = i + 1;
        }

        //Substitution Part Details
        this.partJSON[0].subs = this.listSubstitutionDetails.map((el) => {
          return {
            sequence: el.sequence,
            part_key: el.sub_part_key,
            ware_key: el.ware_key,
          };
        });
        for (let i = 0; i < this.partJSON[0].subs.length; i++) {
          this.partJSON[0].subs[i].sequence = i + 1;
        }
        //Kit/Bundle Part Details
        this.partJSON[0].bom = this.listMemberPartDetails.map((el) => {
          return {
            member_part_key: el.member_part_key,
            qty: el.qty,
            primary_pn: el.primary_pn,
            active: el.active,
            add_on: el.add_on,
          };
        });
        this.user_key = parseInt(this.userId);
        let jsonObj = {
          json: JSON.stringify(this.partJSON[0]),
        };
        this.axios
          .post("/pe/parts_update", jsonObj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              if (this.autoGenerateBCNValue == true && this.autoGeneratedPrefixValue == "") {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: "Auto-Generated Prefix is required",
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              } else if (this.promptRevLevelValue == true && this.minRevLevelValue == "") {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: "Minimum Revision Level Required",
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              } else if (this.activateStrikeRuleValue == true && this.thirdStrikeValue == 0) {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: "Strike Rule Is Checked But Visit Is Zero",
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "success",
                  isShow: true,
                  message: "Part updated successfully",
                };
                this.$store.commit("ConfigModule/Alert", Alert);
                this.resetFunction();
                this.clearDataWarehouse();
                this.clearWarehouseFields();
                this.getPartHeaderByID(this.partsID);
                this.pageChanged = false;
              }
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            let res = JSON.parse(error.response.data.message);
            let errorString = "";
            res.Errors.forEach((obj) => {
              errorString += obj.Error + ",";
            });
            errorString = errorString.slice(0, -1);
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: errorString,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          });
      }
    },

    onClickChangePart() {
      this.showPartDialog = true
    },
    closePartNumDialog() {
      this.showPartDialog = false;
      this.changedPartNum = ""
    },
    async changePartNum() {
      let changeObj = {
        user_id: this.userId,
        from_part_key: this.partsID,
        to_pn: this.changedPartNum
      }
      let response = await partsEditService.changePartNum("post", changeObj)

      if (response?.response) {
        let Alert = {
          type: "success",
          isShow: true,
          message: response?.response,
        };
        this.$store.commit("ConfigModule/Alert", Alert);

        this.getPartHeaderByID(this.partsID);
        this.showPartDialog = false;
        this.closePartNumDialog()
      }
    },
  },
  components: {
    breadcrumbComp,
    draggable,
    PrinterDetails,
    BackToTop,
  },
};

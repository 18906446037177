import axios from "axios";
import Utility from "../../../shared/utility";
import { store } from "../../../state-management/state-mgmt";
export default {
  getBinType() {
    let call = "get";
    return this.getData("/pe/parts_bin_type_get", call);
  },

  getLabelSize() {
    let call = "get";
    return this.getData("/pe/parts_label_size", call);
  },

  getPartType(call, proj_key, isAdd) {
    return this.getData(
      `/pe/part_type_get?proj_key=${proj_key}&isAdd=${isAdd}`,
      call
    );
  },

  getUOM() {
    let call = "get";
    return this.getData("/pe/parts_uom_get", call);
  },

  getModel(userId, ProjKey) {
    let call = "get";
    return this.getData(
      `/pe/parts_model_get?UserId=${userId}&ProjKey=${ProjKey}`,
      call
    );
  },

  getOEMModel(userId, ProjKey) {
    let call = "get";
    return this.getData(
      `/pe/parts_oem_model_get?UserId=${userId}&ProjKey=${ProjKey}`,
      call
    );
  },

  getProject(userId) {
    let call = "get";
    return this.getData(`/cp/project_header?pass=1&user_id=${userId}`, call);
  },

  getOem(userId, ProjKey) {
    let call = "get";
    return this.getData(
      `/pe/parts_oem_get?UserId=${userId}&ProjKey=${ProjKey}`,
      call
    );
  },

  getPartClassListSearch(userId) {
    let call = "get";
    return this.getData(`pe/parts_editor_getclass?UserId=${userId}`, call);
  },

  getOdm(userId, ProjKey) {
    let call = "get";
    return this.getData(
      `/pe/parts_odm_get?UserId=${userId}&ProjKey=${ProjKey}`,
      call
    );
  },

  getBuilderList() {
    let call = "get";
    return this.getData("/pe/parts_build_action", call);
  },

  getBusiness(userId) {
    let call = "get";
    return this.getData(`/cp/business_header?Pass=1&UserId=${userId}`, call);
  },

  getPartClassAdd(userId) {
    let call = "get";
    return this.getData(`/pe/parts_class_get?UserId=${userId}`, call);
  },

  populateData(userId, part_id) {
    let call = "get";
    return this.getData(
      `/pe/parts_editor_header?user_id=${userId}&parts_id=${part_id}`,
      call
    );
  },

  //Adarsh remove this one
  // postBusinessData(obj) {
  //   let call = 'post';
  //   return this.getData('/co/business_getbyId', call, obj);
  // },

  postWarehouseData(obj) {
    let call = "post";
    return this.getData("/cp/warehouse_list", call, obj);
  },

  postSearchData(obj) {
    let call = "post";
    return this.getData("/pe/parts_editor_search", call, obj);
  },

  changePartNum(call,obj)
  {
    return this.getData("/pe/parts_change_part_number", call, obj);
  },


  async getData(url, call, obj) {
    let LoaderDialog = {
      visible: true,
      title: "Please Wait...",
    };
    store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    let res = {};
    if (call == "get") {
      res = await axios.get(url).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    } else {
      res = await axios.post(url, obj).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    }
    if (res.data.body.message != undefined || res.data.body.message != null) {
      if (!res?.data?.body?.message) {
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return {};
      }
      try {
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse(res?.data?.body?.message);
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};
      }
    } else {
      if (!res?.data?.message) {
        return {};
      }
      try {
        return JSON.parse(res?.data?.message);
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};
      }
    }
  },
};
